<template>
  <span class="input-upload" v-if="attachment.fileId === ''">
    <input :id="uploadId" type="file" class="input-upload__input" @change="uploadImage" ref="fileInput">
    <label :for="uploadId" class="input-upload__label tt-uppercase mr-m grey-dark">
      <Icon class="input-upload__icon mr-m" icon="attachment"></Icon><slot>Upload</slot>
    </label>
  </span>
  <div class="d-f" v-else>
    <Attachment @remove-attachment="removeAttachment" :item="attachment" />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import Attachment from '@/02-molecules/Attachment.vue'
import { AttachmentPreview } from '@/services/support/Ticket-service'
import { postUploadsFile } from '@/api/uploads/files-api'

class Props {
  uploadId!: string
}

@Options({
  name: 'Input-upload',
  components: {
    Icon,
    Attachment
  },
  emits: ['add-attachment']
})

/**
 * @param {string} uploadId - Id connecting label and input
 */
export default class InputUpload extends Vue.with(Props) {
  attachment: AttachmentPreview = {
    fileName: '',
    fileId: '',
    file: ''
  }

  uploadImage (ev: Event) {
    if (ev.target) {
      const element = ev.target
      this.attachment.fileName = element['files'][0].name
      this.attachment.file = element['files'][0]

      const file = new FormData()
      file.append('title', this.attachment.fileName)
      file.append('file', element['files'][0])
      file.append('fileType', 'default')
      postUploadsFile(file)
        .then((response) => {
          this.attachment['fileId'] = response.data.id

          this.$emit('add-attachment', this.attachment)
        })
    }
  }

  removeAttachment () {
    this.attachment.fileName = ''
    this.attachment.file = ''
    const fileInput: any = this.$refs['fileInput']
    if (fileInput != null) {
      fileInput.value = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.input-upload {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.input-upload__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.input-upload__label {
  @include brandon-grotesque-bold;
  background: $blue-dark-10;
  border-radius: 30px;
  color: $blue-dark;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  height: 40px;
  padding: 5px 17px 5px 10px;

  &:hover {
    cursor: pointer;
  }
}

.input-upload__icon {
  height: 24px;
  width: 24px;
  margin-right: 6px;
  transform: translateY(-1px);
}
</style>
