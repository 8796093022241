
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Modal from '@/02-molecules/Modal.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputCheckbox from '@/01-atoms/Input-checkbox.vue'
import eventHub from '@/event-hub'
import { inject } from 'vue'
import { ErrorHandlerKey, MemberServiceKey, TripCancellationReasonServiceKey } from '@/services/serviceTypes'
import { PostTripCancellationForm, postTripCancellationForm } from '@/api/documents/documents-api'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Trip-cancellation-form-uploads',
  components: {
    LoadingDots,
    Button,
    Modal,
    InputRadio,
    InputLabel,
    InputCheckbox
  }
})

export default class TripCancellationFormUploads extends Vue {
  medicalFormStep = 1

  memberService = inject(MemberServiceKey)
  tripCancellationReasonService = inject(TripCancellationReasonServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  isLoading = false

  selectedMember = ''
  selectedTripCancellationReason = ''

  downloadLink = ''
  downloadTitle = ''

  beforeMount () {
    this.loadMembers()
    this.loadTripCancellationReasons()
  }

  loadMembers () {
    this.memberService?.loadMembers()
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  loadTripCancellationReasons () {
    this.tripCancellationReasonService?.loadTripCancellationReasons()
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  clickSubmitForm () {
    this.isLoading = true
    this.createTripCancellation()
  }

  createTripCancellation () {
    const tripCancellation: PostTripCancellationForm = {
      'memberId': this.selectedMember,
      'cancellationReason': this.selectedTripCancellationReason
    }
    this.submitTripCancellation(tripCancellation)
  }

  submitTripCancellation (tripCancellation: PostTripCancellationForm) {
    postTripCancellationForm(tripCancellation)
      .then((response) => {
        this.isLoading = false
        this.medicalFormStep++
        this.downloadLink = `data:${response.data.mimeType};base64, ${response.data.content}`
        this.downloadTitle = response.data.fileName
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  requestForm (id: string) {
    eventHub.$emit('open-modal', id)
  }

  resetModal () {
    this.medicalFormStep = 1
    eventHub.$emit('close-modal')
  }
}
