<template>
  <tr class="table-row">
    <td class="table__item-preview">
      <Icon class="mr-m xm-up" background="blue-dark" :icon="item.icon" />
      <div>
        {{ item.title }}
        <span class="d-b grey-dark">{{ item.id }}</span>
      </div>
    </td>
    <td class="fs-14 grey-dark xl-up">{{ formatDate(item.submittedAt) }}</td>
    <td class="fs-14 grey-dark xl-up">{{ item.uploadedBy }}</td>
    <td class="ta-center">
      <Tag :variant="item.status" responsive />
    </td>
  </tr>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '../01-atoms/Icon-button.vue'
import Tag from '../01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import { SupportDocument } from '@/services/support/Upload-document-list-service'

class Props {
  item?: SupportDocument;
}

@Options({
  name: 'Upload-document-table-row',
  components: {
    Icon,
    Tag,
    IconButton
  }
})

export default class TableRowInquiry extends Vue.with(Props) { }
</script>
