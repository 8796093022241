<template>
  <div :class="messageClass">
    <span v-if="item.content.length" class="chat-message__message">{{ item.content }}</span>
    <a v-if="item.attachedFile"
        :download="item.attachedFile.fileName"
        :href="fileDownloadUrl()"
        class="chat-message__file fw-700">
      <Icon icon="file-text" class="chat-message__attachment-icon"></Icon>
      {{ item.attachedFile.fileName }}
    </a>
  </div>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      id: '123e4567-e89b-12d3-a456-426614174000',
      content: 'Lorem Ipsum',
      isFromCmcm: false,
      attachedFile: {
        fileName: 'test.pdf',
        mimeType: 'application/pdf',
        content: '...'
      }
    }
  })
}
@Options({
  name: 'Chat-message',
  components: {
    Icon
  }
})
export default class ChatMessage extends Vue.with(Props) {
  get messageClass () {
    const classes = {}
    classes['chat-message'] = true

    if (!this.item['isFromCmcm']) {
      classes['chat-message--inverse'] = true
    } else {
      classes['chat-message--default'] = true
    }

    return classes
  }

  fileDownloadUrl () {
    if (this.item['attachedFile'] && this.item['attachedFile'].file) {
      return this.item['attachedFile'].file
    } else {
      return `data:${this.item['attachedFile'].mimeType};base64, ${this.item['attachedFile'].content}`
    }
  }
}
</script>
<style lang="scss" scoped>
.chat-message {
  border-radius: 10px;
  background-color: $grey-dark-10;
  color: $grey-dark;
  padding: 14px;
  white-space: pre-wrap;
  max-width: 65%;
  margin-top: $small;

  @include breakpoint(medium down) {
    max-width: 80%;
  }

  &--default + &--inverse,
  &--inverse + &--default {
    margin-bottom: $xmedium;
  }

  &--inverse {
    background-color: $blue-dark-10;
    color: $blue-dark;
    align-self: flex-end;
  }
}

.chat-message__file {
  display: flex;
  align-items: center;
  color: $grey-dark;
  position: relative;
  left: -4px;

  .chat-message__message + & {
    margin-top: 5px;
  }

  .chat-message--inverse & {
    color: $blue-dark;
  }
}

.chat-message__attachment-icon {
  flex-shrink: 0;
}
</style>
