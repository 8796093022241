<template>
  <div class="card">
    <h2 class="display-2 mb-m mt-0">
      <slot name="title">#title</slot>
    </h2>
    <slot>template</slot>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

/**
 * White Card
 *
 * @description {string} template#title
 * @description {HTML} template
 */

@Options({
  name: 'Card'
})

export default class Card extends Vue { }
</script>

<style lang="scss" scoped>
.card {
  @include white-box;
}
</style>
