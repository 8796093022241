
import { Options, Vue } from 'vue-class-component'
import ChevronButton from '@/01-atoms/Chevron-button.vue'

@Options({
  name: 'Accordion',
  components: {
    ChevronButton
  }
})

/**
 *
 * Accordion with chevron button template
 *
 * @description {string} template#title
 * @description {HTML} template
 *
 */
export default class Accordion extends Vue {
  showAccordion = false

  toggleAccordion () {
    if (this.showAccordion) {
      this.showAccordion = false
    } else {
      this.showAccordion = true
    }
  }
}
