
import { Options, Vue } from 'vue-class-component'
import IconButton from '../01-atoms/Icon-button.vue'
import Tag from '../01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import { SupportDocument } from '@/services/support/Upload-document-list-service'

class Props {
  item?: SupportDocument;
}

@Options({
  name: 'Upload-document-table-row',
  components: {
    Icon,
    Tag,
    IconButton
  }
})

export default class TableRowInquiry extends Vue.with(Props) { }
