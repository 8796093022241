<template>
  <div class="d-f">
    <div class="chat-reply-box_container">
      <textarea
          ref="textarea"
          @keypress="updateTextArea($event)"
          class="chat-reply-box__input"
          :placeholder="$t('support.inbox.message.reply-placeholder')"
      />
      <input id="attachment" type="file" class="chat-reply-box__file" @change="addAttachment" ref="fileInput">
      <label
          for="attachment"
          class="chat-reply-box__add-button"
          :aria-label="$t('support.inbox.message.add-attachment-button')"
      >
        <Icon icon="attachment" background="transparent" round></Icon>
      </label>
    </div>
    <IconButton
        round
        @click="submitMessage"
        icon="corner-right-up"
        background="transparent"
        class="chat-reply-box__button"
        :aria-label="$t('support.inbox.message.submit-button')"
    />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import eventHub from '@/event-hub'
import { postUploadsFile } from '@/api/uploads/files-api'
import { AttachmentPreview } from '@/services/support/Ticket-service'

@Options({
  name: 'Chat-reply-box',
  components: {
    IconButton,
    Icon
  },
  emits: ['submit-message', 'add-attachment']
})
export default class ChatReplyBox extends Vue {
  submitMessage () {
    const textarea: any = this.$refs['textarea']
    const messageContent = textarea.value
    this.$emit('submit-message', messageContent)
    this.resetTextArea()
  }

  addAttachment (ev: Event) {
    const element = ev.target!

    const attachment = {
      fileName: element['files'][0].name,
      file: URL.createObjectURL(element['files'][0])
    } as AttachmentPreview

    const file = new FormData()
    file.append('title', 'test')
    file.append('file', element['files'][0])
    file.append('fileType', 'default')
    postUploadsFile(file)
      .then((response) => {
        attachment['fileId'] = response.data.id
        this.$emit('add-attachment', attachment)

        this.focusTextArea()
      })
  }

  focusTextArea () {
    const textarea: any = this.$refs['textarea']
    textarea.focus()
  }

  pressEnter (ev: Event) {
    if (ev['key'] === 'Enter') {
      return true
    }
  }

  pressShiftEnter (ev: Event) {
    if (ev['key'] === 'Enter' && ev['shiftKey']) {
      return true
    }
  }

  updateTextAreaHeight () {
    //from https://stackoverflow.com/a/24676492
    const textarea: any = this.$refs['textarea']
    this.resetTextAreaHeight()
    textarea['style'].height = (textarea['scrollHeight']) + 'px'
  }

  addLineToTextAreaHeight () {
    const textarea: any = this.$refs['textarea']
    textarea['style'].height = (textarea['scrollHeight'] + 13) + 'px'
  }

  resetTextArea () {
    const textarea: any = this.$refs['textarea']
    textarea['value'] = ''
    this.resetTextAreaHeight()
  }

  resetTextAreaHeight () {
    const textarea: any = this.$refs['textarea']
    textarea['style'].height = 23 + 'px'
  }

  updateTextArea (ev: Event) {
    this.updateTextAreaHeight()
    if (this.pressShiftEnter(ev)) {
      this.addLineToTextAreaHeight()
    } else if (this.pressEnter(ev)) {
      ev.preventDefault()
      this.submitMessage()
    }
  }

  resetFileInput () {
    const fileInput: any = this.$refs['fileInput']
    if (fileInput != null) {
      fileInput.value = ''
    }
  }

  mounted () {
    eventHub.$on('reset-file-input', this.resetFileInput)
  }
}
</script>
<style lang="scss" scoped>
.chat-reply-box_container {
  flex-grow: 1;
  position: relative;
  background-color: $blue-dark-10;
  border-radius: 25px;
  border: none;
  padding: 10px 50px 7px 15px;
}

.chat-reply-box__input {
  background-color: transparent;
  border: none;
  color: $blue-dark;
  font-size: 14px;
  width: 100%;
  resize: none;
  overflow: hidden;
  height: 23px;
  max-height: 150px;

  &::placeholder {
    color: $blue-dark;
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
}

.chat-reply-box__button {
  padding-top: 15px;
  margin-left: 15px;
}

.chat-reply-box__file {
  height: 0;
  position: absolute;
  opacity: 0;
  width: 0;
}

.chat-reply-box__add-button {
  position: absolute;
  right: 10px;
  top: 8px;

  &:hover {
    cursor: pointer;
  }

  .chat-reply-box__file:focus + & {
    outline: 2px solid $blue-dark;
  }
}
</style>
