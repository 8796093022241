
import { Options, Vue } from 'vue-class-component'
import IconButton from '../01-atoms/Icon-button.vue'
import Tag from '../01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import router from '@/router'
import { DocumentBase } from '@/services/documents/DocumentService'

class Props {
  item?: DocumentBase;
}

@Options({
  name: 'Inquiries-table-row',
  components: {
    Icon,
    Tag,
    IconButton
  }
})

export default class TableRowDocument extends Vue.with(Props) {
  clickEntry (ev: Event, id: string) {
    const target: any = ev.target
    if (!target['classList'].contains('js-action')) {
      router.push({ name: 'Document', params: { id: id } })
    }
  }

  actionFactory () {
    if (this.item) {
      const actionButton = {
        status: '',
        icon: '',
        route: {
          name: 'Document', params: { id: this.item.id }
        }
      }

      switch (this.item['status']) {
        case 'pending':
          if (this.item['documentType'] === 'invoice') {
            actionButton['status'] = 'pay_now'
            actionButton['icon'] = 'credit-card'
            actionButton['route'] = {
              name: 'Document payment', params: { id: this.item.id }
            }
          }
          break
        case 'to_be_renewed':
          actionButton['status'] = 'upload_now'
          actionButton['icon'] = 'upload'
          actionButton['route'] = {
            name: 'Document upload', params: { id: this.item.id }
          }
          break
      }

      return actionButton
    }
  }
}
