
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Modal from '@/02-molecules/Modal.vue'
import CnsUploadSelect from '@/01-atoms/Cns-upload-select.vue'
import CnsUpload from '@/02-molecules/Cns-upload.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { getFileDownloads, postUploadsFile } from '@/api/uploads/files-api'
import QrCode from '@/02-molecules/Qr-code.vue'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import { DocumentFile } from '@/services/documents/DocumentService'
import eventHub from '@/event-hub'

export interface PreviousUploadTemplate {
  itemId: string;
  imageContent: string;
}

class Props {
  newCnsUploads?: Array<Upload>;
}

@Options({
  name: 'Cns-upload',
  components: {
    LoadingDots,
    QrCode,
    Button,
    Modal,
    CnsUploadSelect,
    CnsUpload
  },
  emits: ['add-new-cns-upload', 'remove-new-cns-upload', 'add-qr-file', 'add-previous-cns-uploads']
})
export default class CnsUploads extends Vue.with(Props) {
  isLoading = false

  previousCnsUploads = [] as Array<DocumentFile>
  previousCnsUploadsToAdd = [] as Array<PreviousUploadTemplate>

  beforeMount () {
    this.loadCnsDocuments()
  }

  addPreviousCnsUploads () {
    this.$emit('add-previous-cns-uploads', this.previousCnsUploadsToAdd)
    eventHub.$emit('close-modal')
  }

  updatePreviousCnsUploadSelect (itemId: string, imageContent: string, addItem: boolean) {
    if (addItem) {
      this.previousCnsUploadsToAdd.push({ itemId: itemId, imageContent: imageContent })
    } else {
      this.previousCnsUploadsToAdd = this.previousCnsUploadsToAdd.filter((upload) => { return upload.itemId !== itemId })
    }
  }

  loadCnsDocuments () {
    getFileDownloads('cns', 1, 6)
      .then((results) => {
        this.previousCnsUploads = results.data
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  newUpload = {
    title: '',
    file: '',
    id: '',
    fileType: 'cns'
  }

  createFile (ev: Event) {
    if (ev.target) {
      this.newUpload.title = ev.target['files'][0].name
      this.newUpload.file = URL.createObjectURL(ev.target['files'][0])

      const file = new FormData()
      file.append('title', ev.target['files'][0].name)
      file.append('file', ev.target['files'][0])
      file.append('fileType', 'cns')

      this.uploadFile(file)
    }
  }

  errorHandlerService = inject(ErrorHandlerKey)
  uploadFile (file: any) {
    this.isLoading = true
    postUploadsFile(file)
      .then((response) => {
        this.displayNewUpload(response.data.id)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  displayNewUpload (id: string) {
    this.newUpload['id'] = id
    this.$emit('add-new-cns-upload', this.newUpload)
    this.isLoading = false

    this.resetNewUploadForm()
  }

  showUploadForm = false
  resetNewUploadForm () {
    this.newUpload = {
      title: '',
      file: '',
      id: '',
      fileType: 'cns'
    }

    this.showUploadForm = false
  }

  addQrFile (file: any, type: 'default' | 'cns') {
    this.$emit('add-qr-file', file, type)
  }

  removeNewCnsUpload (id: string) {
    const uploadsInput: any = this.$refs['cns-uploads-input']
    if (uploadsInput) {
      uploadsInput.value = ''
    }
    this.$emit('remove-new-cns-upload', id)
  }
}
