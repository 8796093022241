<template>
  <div :class="{ 'family-member-card_zoom-in-overlay' : cardIsZoomedIn }" ref="member-card">
    <IconButton
        round
        icon="x"
        @click="zoomOutCard"
        v-if="cardIsZoomedIn"
        background="white"
        class="family-member-card__close-button"
        aria-label="Close"
    />
    <div class="family-member-card placeholder-animation">
      <IconButton
          round
          icon="zoom-in"
          v-if="!cardIsZoomedIn"
          class="family-member-card__zoom-in-button"
          @click="zoomInCard"
          aria-label="zoom card"
      />
      <div class="family-member-card__header mb-m">
        <Logo class="family-member-card__logo" />
        <img src="@/assets/placeholder-images/card-barcode.png" class="family-member-card__barcode" alt="" />
      </div>
      <span class="family-member-card__name fw-700 fs-20 d-b mb-m">{{ member.firstName }} {{ member.lastName }}</span>
      <div class="family-member-card__meta">
        <div>
          <span class="tt-uppercase fs-12 d-b mb-s">{{ $t('family.member.date-of-birth')}}:</span>
          <span class="display-3">{{ formattedBirthDate }}</span>
        </div>
        <div>
          <span class="tt-uppercase fs-12 d-b mb-s">{{ $t('family.member.cmcm-number')}}:</span>
          <span class="display-3">{{ member.matricule }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="family-member-card__placeholder" v-if="cardIsZoomedIn"></div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Logo from '@/assets/logo-minimal.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { format } from 'date-fns'

class Props {
  member!: object
}

@Options({
  name: 'Family-member-card',
  components: {
    Logo,
    IconButton
  }
})
export default class FamilyMemberCard extends Vue.with(Props) {
  cardIsZoomedIn = false

  zoomInCard () {
    this.cardIsZoomedIn = true
    disableBodyScroll(this.$refs['member-card'])
  }

  zoomOutCard () {
    this.cardIsZoomedIn = false
    enableBodyScroll(this.$refs['member-card'])
  }

  get formattedBirthDate () {
    const birthdate = this.member['birthDate']
    if (birthdate) {
      return format(new Date(this.member['birthDate']), 'dd-MM-yyyy')
    }
    return '/'
  }
}
</script>
<style lang="scss" scoped>
.family-member-card_zoom-in-overlay {
  @include z-index('card');
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &::before {
    content: '';
    background-color: black;
    position: absolute;
    top: -100vh;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}

.family-member-card {
  background-color: $blue-dark-10;
  border-radius: 10px;
  padding: 25px;
  position: relative;
  max-width: 365px;
  transition: transform 0.2s ease;
  transform-origin: center;
  width: 100%;

  .is-loading & {
    overflow: hidden;
  }

  .family-member-card_zoom-in-overlay & {
    transform: scale(1.5);

    @include breakpoint(xmedium down) {
      transform: rotate(-90deg) scale(1.3);
    }
  }
}

.family-member-card__zoom-in-button {
  position: absolute;
  top: -15px;
  right: 50%;
  transform: translateX(50%);
}

.family-member-card__header,
.family-member-card__meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.family-member-card__name {
  display: flex;
  align-items: center;
  min-height: 75px;
}

.family-member-card__logo {
  max-width: 105px;
  width: 50%;
}

.family-member-card__barcode {
  max-width: 105px;
  width: 50%;
}

.family-member-card__close-button {
  position: absolute;
  top: $xmedium;
  right: $xmedium;
}

.family-member-card__placeholder {
  height: 230px;
}
</style>
