
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import eventHub from '@/event-hub'
import { postUploadsFile } from '@/api/uploads/files-api'
import { AttachmentPreview } from '@/services/support/Ticket-service'

@Options({
  name: 'Chat-reply-box',
  components: {
    IconButton,
    Icon
  },
  emits: ['submit-message', 'add-attachment']
})
export default class ChatReplyBox extends Vue {
  submitMessage () {
    const textarea: any = this.$refs['textarea']
    const messageContent = textarea.value
    this.$emit('submit-message', messageContent)
    this.resetTextArea()
  }

  addAttachment (ev: Event) {
    const element = ev.target!

    const attachment = {
      fileName: element['files'][0].name,
      file: URL.createObjectURL(element['files'][0])
    } as AttachmentPreview

    const file = new FormData()
    file.append('title', 'test')
    file.append('file', element['files'][0])
    file.append('fileType', 'default')
    postUploadsFile(file)
      .then((response) => {
        attachment['fileId'] = response.data.id
        this.$emit('add-attachment', attachment)

        this.focusTextArea()
      })
  }

  focusTextArea () {
    const textarea: any = this.$refs['textarea']
    textarea.focus()
  }

  pressEnter (ev: Event) {
    if (ev['key'] === 'Enter') {
      return true
    }
  }

  pressShiftEnter (ev: Event) {
    if (ev['key'] === 'Enter' && ev['shiftKey']) {
      return true
    }
  }

  updateTextAreaHeight () {
    //from https://stackoverflow.com/a/24676492
    const textarea: any = this.$refs['textarea']
    this.resetTextAreaHeight()
    textarea['style'].height = (textarea['scrollHeight']) + 'px'
  }

  addLineToTextAreaHeight () {
    const textarea: any = this.$refs['textarea']
    textarea['style'].height = (textarea['scrollHeight'] + 13) + 'px'
  }

  resetTextArea () {
    const textarea: any = this.$refs['textarea']
    textarea['value'] = ''
    this.resetTextAreaHeight()
  }

  resetTextAreaHeight () {
    const textarea: any = this.$refs['textarea']
    textarea['style'].height = 23 + 'px'
  }

  updateTextArea (ev: Event) {
    this.updateTextAreaHeight()
    if (this.pressShiftEnter(ev)) {
      this.addLineToTextAreaHeight()
    } else if (this.pressEnter(ev)) {
      ev.preventDefault()
      this.submitMessage()
    }
  }

  resetFileInput () {
    const fileInput: any = this.$refs['fileInput']
    if (fileInput != null) {
      fileInput.value = ''
    }
  }

  mounted () {
    eventHub.$on('reset-file-input', this.resetFileInput)
  }
}
