
import { Options, prop, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      fileName: 'file.pdf'
    }
  })
}

@Options({
  name: 'Attachment',
  components: {
    Icon,
    IconButton
  },
  emits: ['remove-attachment']
})

/**
 * Attachment file box with remove button
 *
 * @param {string} attachedFileName
 */
export default class Attachment extends Vue.with(Props) {
  removeAttachment () {
    this.$emit('remove-attachment', this.item['id'])
  }
}
