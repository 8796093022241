
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import Attachment from '@/02-molecules/Attachment.vue'
import { AttachmentPreview } from '@/services/support/Ticket-service'
import { postUploadsFile } from '@/api/uploads/files-api'

class Props {
  uploadId!: string
}

@Options({
  name: 'Input-upload',
  components: {
    Icon,
    Attachment
  },
  emits: ['add-attachment']
})

/**
 * @param {string} uploadId - Id connecting label and input
 */
export default class InputUpload extends Vue.with(Props) {
  attachment: AttachmentPreview = {
    fileName: '',
    fileId: '',
    file: ''
  }

  uploadImage (ev: Event) {
    if (ev.target) {
      const element = ev.target
      this.attachment.fileName = element['files'][0].name
      this.attachment.file = element['files'][0]

      const file = new FormData()
      file.append('title', this.attachment.fileName)
      file.append('file', element['files'][0])
      file.append('fileType', 'default')
      postUploadsFile(file)
        .then((response) => {
          this.attachment['fileId'] = response.data.id

          this.$emit('add-attachment', this.attachment)
        })
    }
  }

  removeAttachment () {
    this.attachment.fileName = ''
    this.attachment.file = ''
    const fileInput: any = this.$refs['fileInput']
    if (fileInput != null) {
      fileInput.value = ''
    }
  }
}
