<template>
  <div class="support-inbox-chat" :class="[isLoading && 'is-loading', showMessages && 'show-messages']" :style="containerHeight" ref="support-inbox-chat">
    <div class="support-inbox-chat__header placeholder-animation">
      <div class="support-inbox-chat__back-button">
        <IconButton icon="chevron-left" :to="{name: 'Inbox'}" class="mr-m" round background="transparent" />
      </div>
      <div class="support-inbox-chat__title">
        <h2 class="fw-700 fs-16 mb-0 mt-0 d-f" v-if="ticket.topic">
          {{ ticket.topic.topic }}
        </h2>
        <span class="support-inbox-chat__header-date fs-12 tt-uppercase">
          {{ $t('support.inbox.message.start-date', {date: formatDate(ticket.createdAt)}) }}
        </span>
      </div>
    </div>
    <div class="support-inbox-chat__messages_container" ref="chat-messages">
      <Observer @is-intersecting="intersectingForLoading" />
      <div v-if="!isLoadingMoreMessages" class="support-inbox-chat__loading-dots-placeholder" />
      <LoadingDots class="support-inbox-chat__loading-dots" v-if="isLoading || isLoadingMoreMessages" />
      <div class="support-inbox-chat__messages mb-m" ref="chat-messages-scroll" v-if="!isLoading">
        <ChatMessage class="support-inbox-chat__message" v-for="item in messages" :item="item" v-bind:key="item.id" />
      </div>
    </div>
    <div>
      <div class="d-f" v-if="attachmentPreview.fileName !== ''">
        <ChatAttachment @remove-attachment="removeAttachment" :item="attachmentPreview" class="mb-s" />
      </div>
      <ChatReplyBox @submit-message="submitMessage" @add-attachment="addAttachment"  />
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ChatMessage from '@/02-molecules/Chat-message.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'
import ChatAttachment from '@/02-molecules/Attachment.vue'
import ChatReplyBox from '@/02-molecules/Chat-reply-box.vue'
import eventHub from '@/event-hub'
import calculateContainerHeight from '@/helpers/container-height'
import { getSupportTicket, getSupportTicketMessages, getSupportTickets, postSupportTicketMessage } from '@/api/support/tickets-api'
import { AttachmentPreview, Message, NewMessage, Ticket } from '@/services/support/Ticket-service'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import { ErrorHandlerKey, TicketServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'
import Observer from '@/01-atoms/Observer.vue'

@Options({
  name: 'Support-inbox--chat',
  components: {
    Observer,
    LoadingDots,
    ChatMessage,
    IconButton,
    Icon,
    ChatAttachment,
    ChatReplyBox
  },
  emits: ['load-tickets'],
  watch: {
    $route (to) {
      if (to.name === 'Chat') {
        this.loadTicket()
      }
    }
  }
})
export default class SupportInboxChat extends Vue {
  containerHeight: any = ''

  ticket = {} as Ticket

  messages = [] as Array<Message>

  isLoading = true

  showMessages = false

  attachmentPreview: AttachmentPreview = {
    fileName: '',
    fileId: '',
    file: ''
  }

  itemPerPage = 20
  currentPage = 1
  isLoadingMoreMessages = false

  beforeMount () {
    this.loadTicket()
  }

  checkForNewMessages () {
    const latestTicket: Ticket = this.ticketService?.tickets[0]!
    getSupportTickets(1, 1)
      .then((response) => {
        if (latestTicket.createdAt !== response.data[0].createdAt) {
          this.addCmCmMessage(response.data[0])
        }
      })
  }

  addCmCmMessage (ticket: Ticket) {
    if (ticket.lastMessage.isFromCmcm) {
      this.messages = [ticket.lastMessage, ...this.messages]
      this.$emit('load-tickets')
    }
  }

  intersectingForLoading () {
    if (!this.isLoadingMoreMessages && !this.isLoading && this.showMessages) {
      this.loadOlderMessages()
    }
  }

  loadOlderMessages () {
    const id = this.$route.params.id.toString()
    this.isLoadingMoreMessages = true
    getSupportTicketMessages(id, this.currentPage + 1, this.itemPerPage)
      .then((response) => {
        if (response.data.length !== 0) {
          this.currentPage++
        }

        const element: any = this.$refs['chat-messages-scroll']
        const oldHeight = element.scrollHeight

        this.messages = this.messages.concat(response.data)
        this.isLoadingMoreMessages = false

        this.resetScrollPosition(oldHeight)
      })
  }

  resetScrollPosition (height: number) {
    setTimeout(() => {
      const element: any = this.$refs['chat-messages-scroll']
      const newHeight = element.scrollHeight
      const difference = newHeight - height
      const container: any = this.$refs['chat-messages']
      container.scrollTop = difference
    }, 50)
  }

  loadTicket () {
    this.currentPage = 1
    const id = this.$route.params.id.toString()
    this.isLoading = true
    this.showMessages = false

    getSupportTicket(id, this.itemPerPage)
      .then((response) => {
        this.ticket = response.data
        this.messages = response.data.messages
        this.containerHeight = calculateContainerHeight(this.$refs['support-inbox-chat'])
        this.isLoading = false
        this.initialiseChatFocus()
      })
  }

  initialiseChatFocus () {
    const container: any = this.$refs['chat-messages']
    setTimeout(() => {
      container.scrollTop = container.scrollHeight
      this.showMessages = true
    }, 500)
  }

  errorHandlerService = inject(ErrorHandlerKey)
  ticketService = inject(TicketServiceKey)

  submitMessage (messageContent: string) {
    const id = this.$route.params.id.toString()
    const message: NewMessage = this.createNewMessage(messageContent)

    if (this.validateMessage(message)) {
      this.createVisualMessage(messageContent)
      postSupportTicketMessage(id, message)
        .then(() => {
          this.ticketService?.reloadNewestTicket()
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
      this.removeAttachment()
    }
  }

  createNewMessage (messageContent: string) {
    const newMessage: NewMessage = {
      content: messageContent
    }
    if (this.attachmentPreview.fileId) {
      newMessage.attachedFile = this.attachmentPreview.fileId
    }
    return newMessage
  }

  createVisualMessage (messageContent: string) {
    const timestamp = (new Date().valueOf()).toString()
    const message: Message = {
      id: timestamp,
      isFromCmcm: false,
      content: messageContent
    }

    if (this.attachmentPreview.fileId) {
      message['attachedFile'] = this.attachmentPreview
    }

    this.updateChat(message)
  }

  updateChat (message: Message) {
    this.messages = [message, ...this.messages]
    this.focusChat()
  }

  focusChat () {
    setTimeout(() => {
      const container: any = this.$refs['chat-messages']
      container.scrollTop = container.scrollHeight
    }, 50)
  }

  validateMessage (message: NewMessage) {
    return message.content.length > 0 || message.attachedFile
  }

  addAttachment (attachment: AttachmentPreview) {
    this.attachmentPreview = attachment
  }

  removeAttachment () {
    this.attachmentPreview = {
      fileName: '',
      fileId: '',
      file: ''
    }
    eventHub.$emit('reset-file-input')
  }
}
</script>
<style lang="scss" scoped>
.support-inbox-chat {
  @include white-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 310px);

  @include breakpoint($inbox-breakpoint down) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: calc(100vh - 100px);
    padding: $medium;
  }
}

.support-inbox-chat__header {
  display: block;
  border-bottom: 1px solid $blue-dark-10;
  padding-bottom: $medium;

  @include breakpoint(xmedium down) {
    display: flex;
  }
}

.support-inbox-chat__title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint(xmedium down) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.support-inbox-chat__messages {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.support-inbox-chat__messages_container {
  height: 100%;
  overflow-y: auto;
  padding-top: $medium;
  margin-bottom: $medium;

  // create space for the scroll bar
  margin-right: -15px;
  padding-right: 15px;
}

.support-inbox-chat__back-button {
  display: none;

  @include breakpoint($inbox-breakpoint down) {
    display: block;
  }
}

.support-inbox-chat__loading-dots-placeholder {
  display: block;
  height: 40px;
}

.support-inbox-chat__loading-dots {
  margin: 20px 0;
}

.support-inbox-chat__message {
  opacity: 0;
  transition: opacity 0.4s ease;

  .show-messages & {
    opacity: 1;
  }
}
</style>
