<template>
  <router-link :to="{ name: item.title, params: { username: 'erina' }}">
    <div class="setting-card">
      <h2 class="display-2 mb-m mt-0 blue-dark">
        <Icon :icon="item.icon" background="blue-dark" class="d-b mb-s"></Icon>
        <slot name="title">Title</slot>
      </h2>
      <div class="grey-dark mb-m">
        <slot>Main Content</slot>
      </div>
      <div class="setting-card__steps grey-dark fw-700" v-if="item.totalSteps">
        <div>
          {{ remainingSteps() }}
          <span v-if="remainingSteps() === 1"> step left</span>
          <span v-else> steps left</span>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      icon: 'lock',
      title: 'Title',
      content: 'Content',
      totalSteps: '3',
      step: '2'
    }
  })
}

@Options({
  name: 'Setting-card',
  components: {
    Icon
  }
})
export default class SettingCard extends Vue.with(Props) {
  remainingSteps () {
    const remainingSteps = parseInt(this.item['totalSteps']) - parseInt(this.item['step'])
    return remainingSteps
  }
}
</script>
<style lang="scss" scoped>
.setting-card {
  @include white-box;
  height: 100%;
}
</style>
