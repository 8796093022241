
import { Options, Vue } from 'vue-class-component'
import { SupportDocument } from '@/services/support/Upload-document-list-service'
import getTimeAgo from '@/helpers/get-time-ago'
import TableHeader from '@/02-molecules/Table-header.vue'
import Table from '@/02-molecules/Table.vue'
import { ErrorHandlerKey, UploadDocumentListServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'
import TableRowInquiry from '@/02-molecules/Table-row-inquiry.vue'
import TableRowUploadDocument from '@/02-molecules/Table-row-upload-document.vue'
import eventHub from '@/event-hub'

@Options({
  name: 'Support Upload Document List',
  components: {
    TableRowUploadDocument,
    TableRowInquiry,
    Table,
    TableHeader
  }
})

export default class SupportUploadDocumentList extends Vue {
  isLoading = true
  currentPage = 1
  itemPerPage = 5
  uploadDocumentListService = inject(UploadDocumentListServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  tableHeaders = [
    {
      title: 'table.date',
      responsive: true
    },
    {
      title: 'table.uploadedBy',
      responsive: true
    },
    {
      title: 'table.inquiry',
      responsive: false
    }
  ]

  beforeMount () {
    this.loadUploadDocumentList()
  }

  loadUploadDocumentList () {
    this.uploadDocumentListService?.loadUploadDocuments()
        .then(() => {
          this.isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  clickRow (ev: Event, item: SupportDocument) {
    ev.preventDefault()
    eventHub.$emit('open-modal', 'new-message')
    eventHub.$emit('set-textarea', `"${item.title}" (${item.id})`)
  }

  sortDocuments () {
    const newArray = [[], [], [], [], []]
    this.uploadDocumentListService?.uploadDocuments.forEach((document: SupportDocument) => {
      const index = getTimeAgo(document.submittedAt)!.index
      newArray[index].push(document)
    })
    return newArray
  }

  loadOlderEntries () {
    this.isLoading = true
    const currentPage = this.setCurrentPage()
    this.uploadDocumentListService?.loadOlderDocuments(currentPage, this.itemPerPage)
      .then(() => {
        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  setCurrentPage () {
    this.currentPage = this.currentPage + 1
    return this.currentPage
  }
}
