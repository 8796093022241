<template>
  <h2 class="fs-12 tt-uppercase title-decoration mb-m">
    <span class="title-decoration__label"><slot>Title Decoration</slot></span>
  </h2>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Title-decorations',
  components: {}
})
export default class TitleDecoration extends Vue {
}
</script>
<style lang="scss" scoped>
.title-decoration {
  display: flex;
  align-items: center;

  &::after {
    content: "";
    background-color: $blue-dark-10;
    height: 2px;
    position: relative;
    width: 100%;
  }
}

.title-decoration__label {
  flex-shrink: 0;
  font-weight: 400;
  margin-right: $medium;
}
</style>
