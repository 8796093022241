
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      icon: 'lock',
      title: 'Title',
      content: 'Content',
      totalSteps: '3',
      step: '2'
    }
  })
}

@Options({
  name: 'Setting-card',
  components: {
    Icon
  }
})
export default class SettingCard extends Vue.with(Props) {
  remainingSteps () {
    const remainingSteps = parseInt(this.item['totalSteps']) - parseInt(this.item['step'])
    return remainingSteps
  }
}
