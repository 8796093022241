
import { Options, Vue } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'

class Props {
  item!: object
}

@Options({
  name: 'Document-upload',
  components: {
    Tag,
    Icon,
    Button,
    IconButton
  },
  emits: ['show-uploads-lightbox']
})
export default class DocumentUpload extends Vue.with(Props) {
  showUploadsLightbox () {
    this.$emit('show-uploads-lightbox')
  }
}
