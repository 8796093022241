
import { Options, prop, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { getFileDownload } from '@/api/uploads/files-api'
import { inject } from 'vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import { AttachedFile } from '@/services/support/Ticket-service'
import VuePdfEmbed from 'vue-pdf-embed'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      id: '123e4567-e89b-12d3-a456-426614174000',
      originalFilename: 'file.pdf',
      uploadDate: '2019-12-12 23:12:21',
      pageNumber: 1,
      url: 'https://picsum.photos/seed/cnstwo/600/500',
      fileType: 'default',
      status: 'accepted'
    }
  })
}

@Options({
  name: 'Cns-upload-select',
  components: {
    LoadingDots,
    IconButton,
    VuePdfEmbed
  },
  emits: ['change-checkbox-input', 'update:modelValue']
})
export default class CnsUploadSelect extends Vue.with(Props) {
  lightboxIsVisible = false

  isLoading = true

  imageContent = ''
  errorHandlerService = inject(ErrorHandlerKey)

  isPdf () {
    return this.imageContent.includes('application/pdf')
  }

  updateCheckbox (ev: Event) {
    const checkbox: any = ev.target
    if (checkbox.checked) {
      this.$emit('change-checkbox-input', this.item['id'], this.imageContent, true)
    } else {
      this.$emit('change-checkbox-input', this.item['id'], this.imageContent, false)
    }
  }

  beforeMount () {
    this.loadImage()
  }

  loadImage () {
    getFileDownload(this.item['url'])
      .then((response) => {
        this.displayImage(response.data)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  displayImage (file: AttachedFile) {
    this.imageContent = `data:${file.mimeType};base64, ${file.content}`
    this.isLoading = false
  }

  showLightbox () {
    disableBodyScroll('cns-upload-select')
    this.lightboxIsVisible = true
  }

  hideLightbox () {
    enableBodyScroll('cns-upload-select')
    this.lightboxIsVisible = false
  }
}
