<template>
  <div class="page">
    <h1 class="display-1">MYCMCM Design System</h1>
    <h2>Base</h2>
    <section>
      <h3>Colors <small>_config.scss</small></h3>
      <div class="grid ta-center">
        <div style="width:100px;">
          <div class="background--blue-dark color-swatch">#0033A0</div>
          <br>blue-dark
        </div>
        <div style="width:100px;">
          <div class="background--blue-dark-10 color-swatch color-swatch--dark">#D9E1F1</div>
          <br>blue-dark-10
        </div>
        <div style="width:100px;">
          <div class="background--blue-light color-swatch">#00A9E0</div>
          <br>blue-light
        </div>
        <div style="width:100px;">
          <div class="background--blue-light-10 color-swatch color-swatch--dark">#D3ECF5</div>
          <br>blue-light-10
        </div>
        <div style="width:100px;">
          <div class="background--green color-swatch">#009B4E</div>
          <br>green
        </div>
        <div style="width:100px;">
          <div class="background--green-15 color-swatch color-swatch--dark">#D9F0E5</div>
          <br>green-15
        </div>
        <div style="width:100px;">
          <div class="background--grey-dark color-swatch">#54535B</div>
          <br>grey-dark
        </div>
        <div style="width:100px;">
          <div class="background--grey-dark-10 color-swatch color-swatch--dark">#E6E6E7</div>
          <br>grey-dark-10
        </div>
        <div style="width:100px;">
          <div class="background--grey-dark-20 color-swatch color-swatch--dark">#D7D7D8</div>
          <br>grey-dark-20
        </div>
        <div style="width:100px;">
          <div class="background--grey-light color-swatch color-swatch--dark">#DBDBDB</div>
          <br>grey-light
        </div>
        <div style="width:100px;">
          <div class="background--red color-swatch">#E10600</div>
          <br>red
        </div>
        <div style="width:100px;">
          <div class="background--red-15 color-swatch color-swatch--dark">#FBDAD9</div>
          <br>red-15
        </div>
        <div style="width:100px;">
          <div class="background--white color-swatch color-swatch--dark">#FFFFFF</div>
          <br>white
        </div>
        <div style="width:100px;">
          <div class="background--yellow color-swatch color-swatch--dark">#FFD054</div>
          <br>yellow
        </div>
        <div style="width:100px;">
          <div class="background--yellow-10 color-swatch color-swatch--dark">#FFFAC7</div>
          <br>yellow-10
        </div>
      </div>
      <br>
    </section>
    <section>
      <h3>Breakpoints <small>_config.scss</small></h3>
      <div><span class="display-3 d-b">small, medium, xmedium, large, xlarge, xxlarge, cap</span></div>
      <div class="code">
        <code>
          @include breakpoint({size} down) {
          ... code here
          } <br>
          @include breakpoint({size} up) {
          ... code here
          } <br>
          @include breakpoint({size} only) {
          ... code here
          }
        </code>
      </div>
    </section>
    <br><br>
    <h2>Global</h2>
    <section>
      <h3>Typography  <small>_type.scss</small></h3>
      <div><span class="display-1 d-b">display-1</span> <small>.display-1</small></div>
      <br>
      <div><span class="display-2 d-b">display-2</span> <small>.display-2</small></div>
      <br>
      <div><span class="display-3 d-b">display-3</span> <small>.display-3</small></div>
    </section>
    <section>
      <h3>Utility Classes <small>_utilities.scss</small></h3>
      <div><span class="display-3 d-b">Font-size </span><small>.fs-{size}</small></div>
      <br>
      <div><span class="display-3 d-b">Margin </span><small>.mb-{size}, .mr-{size}</small></div>
      <br>
      <div><span class="display-3 d-b">Hide on screen down </span><small>.{size}-down</small></div>
      <br>
      <div><span class="display-3 d-b">Hide on screen up </span><small>.{size}-up</small></div>
    </section>
    <h2 style="margin-top: 60px;">Components</h2>
    <section>
      <h3>Button.vue</h3>
      <div class="grid">
        <Button>Default</Button>
        <Button inverse>Inverse</Button>
        <Button lowercase>lowercase</Button>
        <Button href="#">Link</Button>
        <Button to="/" >Router Link</Button>
        <Button disabled>Disabled</Button>
      </div>
      <div class="code">
        <code>
          {{ buttonInclude }} <br><br>
          {{ buttonCode1 }} <br>
          {{ buttonCode2 }} <br>
          {{ buttonCode3 }} <br>
          {{ buttonCode4 }} <br>
          {{ buttonCode5 }} <br>
          {{ buttonCode6 }}
        </code>
      </div>
    </section>
    <section>
      <h3>Icon.vue</h3>
      <div class="grid">
        <div class="icon-swatch">
          <Icon icon="home" />
        </div>
        <div class="icon-swatch">
          <Icon icon="home" color="green" />
        </div>
        <div class="icon-swatch">
          <Icon icon="home" background="blue-dark" />
        </div>
        <div class="icon-swatch">
          <Icon icon="bell" notifications="1" />
        </div>
        <div class="icon-swatch">
          <Icon icon="bell" notifications="2" />
        </div>
      </div>
      <div class="code">
        <code>
          {{ iconInclude }} <br><br>
          {{ iconCode1 }} <br>
          {{ iconCode2 }} <br>
          {{ iconCode3 }} <br>
          {{ iconCode4 }} <br>
          {{ iconCode5 }} <br>
        </code>
      </div>
      <details style="margin-top: 30px;">
        <summary>View all Icons</summary>
        <div class="grid">
          <div v-for="icon in iconList" :key="icon" class="icon-swatch">
            <Icon :icon="icon" />{{ icon }}
          </div>
        </div>
      </details>
    </section>
    <section>
      <h3>Icon-Button.vue</h3>
      <div class="grid">
        <IconButton icon="home">Default</IconButton>
        <IconButton background="blue-dark-10" icon="home">Color</IconButton>
        <IconButton background="transparent" icon="home">Transparent</IconButton>
        <IconButton icon="home" lowercase>lowercase</IconButton>
        <IconButton icon="home" small>small</IconButton>
        <IconButton icon="home" medium>medium</IconButton>
        <IconButton icon="document" notifications="1" background="transparent">Notification</IconButton>
      </div>
      <div class="code">
        <code>
          {{ iconButtonInclude }} <br><br>
          {{ iconButton1 }} <br>
          {{ iconButton2 }} <br>
          {{ iconButton3 }} <br>
          {{ iconButton4 }} <br>
          {{ iconButton5 }} <br>
          {{ iconButton6 }} <br>
          {{ iconButton7 }}
        </code>
      </div>
    </section>
    <section>
      <h3>Tag.vue</h3>
      <div class="grid">
        <Tag :variant="'accepted'" />
        <Tag variant="pending" />
        <Tag variant="processing" />
        <Tag variant="rejected" />
        <Tag variant="incomplete" />
        <Tag variant="splitted" />
        <Tag variant="in_progress" />
        <Tag variant="accepted" />
        <Tag variant="reimbursed" />
        <Tag variant="paid" />
        <Tag variant="quote_accepted" />
        <Tag variant="valid" />
        <Tag variant="to_be_renewed" />
      </div>
      <div class="code">
        <code>
          {{ tagInclude }} <br><br>
          {{ tagCodeVariant1 }} <br>
          {{ tagCodeVariant2 }}
        </code>
      </div>
    </section>
    <section>
      <small>Made by lightbulb</small>
    </section>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Icon from '@/01-atoms/Icon.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Tag from '@/01-atoms/Tag.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import InputText from '@/01-atoms/Input-text.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import statusFactory from '@/helpers/status-factory'

@Options({
  components: {
    Button,
    Tag,
    IconButton,
    Icon,
    InputSelect,
    InputText,
    BackButton
  },
  methods: { statusFactory },
  data: () => ({
    // Button
    buttonInclude: 'import Button from \'@/00-atoms/Button.vue\'',
    buttonCode1: '<Button>Default</Button>',
    buttonCode2: '<Button inverse>Inverse</Button>',
    buttonCode3: '<Button lowercase>lowercase</Button>',
    buttonCode4: '<Button href="#">Link</Button>',
    buttonCode5: '<Button to="/" >Router Link</Button>',
    buttonCode6: '<Button disabled>Disabled</Button>',
    // Icon
    iconInclude: 'import Icon from \'@/00-atoms/Icon.vue\'',
    iconCode1: '<Icon icon="home" />',
    iconCode2: '<Icon icon="home" color="green" />',
    iconCode3: '<Icon icon="home" background="blue-dark" />',
    iconCode4: '<Icon icon="bell" notifications="1" />',
    iconCode5: '<Icon icon="bell" notifications="2" />',
    // IconButton
    iconButtonInclude: 'import IconButton from \'@/00-atoms/Icon-button.vue\'',
    iconButton1: '<IconButton icon="home">Default</IconButton>',
    iconButton2: '<IconButton background="blue-dark-10" icon="home">Color</IconButton>',
    iconButton3: '<IconButton background="transparent" icon="home">Transparent</IconButton>',
    iconButton4: '<IconButton icon="home" lowercase>lowercase</IconButton>',
    iconButton5: '<IconButton icon="home" small>small</IconButton>',
    iconButton6: '<IconButton icon="home" medium>medium</IconButton>',
    iconButton7: '<IconButton icon="document" notifications="1" background="transparent">Notification</IconButton>',
    // Tag
    tagInclude: 'import Tag from \'@/00-atoms/Tag.vue\'',
    tagCodeVariant1: '<Tag :variant="{variable}" />',
    tagCodeVariant2: '<Tag :variant="{variable}" responsive />'
  })
})
export default class Atoms extends Vue {
  get iconList () {
    const list: Array<string> = []
    require.context(
      '../assets/icons',
      false,
      /.vue$/
    ).keys().forEach((fileName: string) => {
      const componentName = fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
        .toLowerCase()
      list.push(componentName)
    })
    return list
  }
}
</script>

<style lang="scss" scoped>
.page {
  max-width: 1200px;
  margin: 30px auto;
  padding: 15px;
}

.code {
  background: aliceblue;
  padding: 15px;
  margin-top: 30px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: $medium;
  align-items: center;
}

section {
  margin-top: 60px;
  padding: 0 30px;
}

h3:first-child {
  border-bottom: 1px solid $blue-dark;
  margin-bottom: 30px;
}

h3 small {
  font-weight: 300;
}

.icon-swatch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height:100px;
  text-align:center;
  width:100px;

  svg {
    margin-bottom: 10px;
  }
}

.color-swatch {
  color: white;
  display: inline-block;
  height:100px;
  line-height: 100px;
  margin-bottom: 15px;
  text-align:center;
  width:100px;

  &--dark {
    color: $grey-dark;
  }
}
</style>
