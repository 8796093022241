
import { Options, Vue, prop } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      id: '123e4567-e89b-12d3-a456-426614174000',
      content: 'Lorem Ipsum',
      isFromCmcm: false,
      attachedFile: {
        fileName: 'test.pdf',
        mimeType: 'application/pdf',
        content: '...'
      }
    }
  })
}
@Options({
  name: 'Chat-message',
  components: {
    Icon
  }
})
export default class ChatMessage extends Vue.with(Props) {
  get messageClass () {
    const classes = {}
    classes['chat-message'] = true

    if (!this.item['isFromCmcm']) {
      classes['chat-message--inverse'] = true
    } else {
      classes['chat-message--default'] = true
    }

    return classes
  }

  fileDownloadUrl () {
    if (this.item['attachedFile'] && this.item['attachedFile'].file) {
      return this.item['attachedFile'].file
    } else {
      return `data:${this.item['attachedFile'].mimeType};base64, ${this.item['attachedFile'].content}`
    }
  }
}
