<template>
  <div class="cns-upload-select_container" ref="cns-upload-select">
    <input :id="item.id" class="cns-upload-select__input" type="checkbox" @input="$emit('update:modelValue', $event.target.value)" @change="updateCheckbox">
    <label :for="item.id" class="cns-upload-select__label">
      <div class="cns-upload-select__media mb-m">
        <vue-pdf-embed :page="1" class="cns-upload-select__image" v-if="isPdf()" :source="imageContent" />
        <img v-else class="cns-upload-select__image" :src="imageContent" alt="" />
        <div class="cns-upload-select__checkbox"></div>
      </div>
      <div class="cns-upload-select__footer d-b">
        <span class="fs-16 grey-dark">{{ formatDate(item.uploadedAt) }}</span>
      </div>
    </label>
    <div class="cns-upload-select__zoom-button" v-if="!isPdf">
      <LoadingDots v-if="isLoading" />
      <IconButton v-else @click="showLightbox" background="blue-dark" icon="zoom-in" round />
    </div>
    <vue-easy-lightbox
        moveDisabled
        :imgs="[imageContent]"
        @hide="hideLightbox"
        :visible="lightboxIsVisible"
    ></vue-easy-lightbox>
  </div>
</template>
<script lang="ts">
import { Options, prop, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { getFileDownload } from '@/api/uploads/files-api'
import { inject } from 'vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import { AttachedFile } from '@/services/support/Ticket-service'
import VuePdfEmbed from 'vue-pdf-embed'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      id: '123e4567-e89b-12d3-a456-426614174000',
      originalFilename: 'file.pdf',
      uploadDate: '2019-12-12 23:12:21',
      pageNumber: 1,
      url: 'https://picsum.photos/seed/cnstwo/600/500',
      fileType: 'default',
      status: 'accepted'
    }
  })
}

@Options({
  name: 'Cns-upload-select',
  components: {
    LoadingDots,
    IconButton,
    VuePdfEmbed
  },
  emits: ['change-checkbox-input', 'update:modelValue']
})
export default class CnsUploadSelect extends Vue.with(Props) {
  lightboxIsVisible = false

  isLoading = true

  imageContent = ''
  errorHandlerService = inject(ErrorHandlerKey)

  isPdf () {
    return this.imageContent.includes('application/pdf')
  }

  updateCheckbox (ev: Event) {
    const checkbox: any = ev.target
    if (checkbox.checked) {
      this.$emit('change-checkbox-input', this.item['id'], this.imageContent, true)
    } else {
      this.$emit('change-checkbox-input', this.item['id'], this.imageContent, false)
    }
  }

  beforeMount () {
    this.loadImage()
  }

  loadImage () {
    getFileDownload(this.item['url'])
      .then((response) => {
        this.displayImage(response.data)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  displayImage (file: AttachedFile) {
    this.imageContent = `data:${file.mimeType};base64, ${file.content}`
    this.isLoading = false
  }

  showLightbox () {
    disableBodyScroll('cns-upload-select')
    this.lightboxIsVisible = true
  }

  hideLightbox () {
    enableBodyScroll('cns-upload-select')
    this.lightboxIsVisible = false
  }
}
</script>
<style lang="scss" scoped>
.cns-upload-select_container {
  max-width: 260px;
  position: relative;
  width: 33.3%;

  @include breakpoint(medium down) {
    width: 50%;
  }

  &:before {
    content: '';
    padding-top: calc(141% + 30px);
    float: left;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.cns-upload-select__label {
  background-color: white;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;

  &:hover {
    cursor: pointer;
  }
}

.cns-upload-select__input {
  position: absolute;
  opacity: 0;
}

.cns-upload-select__input:checked + .cns-upload-select__label {
  background-color: transparentize($blue-dark-10, 0.30);
}

.cns-upload-select__media {
  flex-grow: 1;
  position: relative;
}

.cns-upload-select__image {
  @include object-fit;
  border: 4px dashed $blue-dark-10;
  border-radius: 20px;
  overflow: hidden;
}

.cns-upload-select__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cns-upload-select__checkbox {
  border-radius: 50%;
  border: 2px solid $blue-dark;
  height: 25px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 25px;
}

.cns-upload-select__input:checked + .cns-upload-select__label .cns-upload-select__checkbox::after {
  content: '';
  background-color: $blue-dark;
  border-radius: 50%;
  height: 15px;
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 15px;
}

.cns-upload-select__zoom-button {
  position: absolute;
  top: calc(50% - 15px);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}
</style>
