
import { Options, Vue } from 'vue-class-component'
import Accordion from '@/02-molecules/Accordion.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import Button from '@/01-atoms/Button.vue'
import Card from '@/02-molecules/Card.vue'
import CardEdit from '@/02-molecules/Card-edit.vue'
import ChatMessage from '@/02-molecules/Chat-message.vue'
import CnsUpload from '@/02-molecules/Cns-upload.vue'
import DocumentUpload from '@/02-molecules/Document-upload.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import HelpToggle from '@/02-molecules/Help-toggle.vue'
import InquiryUpload from '@/02-molecules/Inquiry-upload.vue'
import Modal from '@/02-molecules/Modal.vue'
import MoreMenu from '@/02-molecules/More-menu.vue'
import NewInquiryUpload from '@/02-molecules/New-inquiry-upload.vue'
import Notifications from '@/02-molecules/Notifications.vue'
import PackageTag from '@/02-molecules/Package-tag.vue'
import Search from '@/02-molecules/Search.vue'
import Separator from '@/02-molecules/Separator.vue'
import SupportInboxChatPreview from '@/02-molecules/Support-inbox-chat-preview.vue'
import Tabs from '@/02-molecules/Tabs.vue'
import TitleDecoration from '@/02-molecules/Title-decoration.vue'

@Options({
  components: {
    Accordion,
    BackButton,
    Button,
    Card,
    CardEdit,
    ChatMessage,
    CnsUpload,
    DocumentUpload,
    HelpText,
    HelpToggle,
    InquiryUpload,
    Modal,
    MoreMenu,
    NewInquiryUpload,
    Notifications,
    PackageTag,
    Search,
    Separator,
    SupportInboxChatPreview,
    Tabs,
    TitleDecoration
  }
})
export default class Molecules extends Vue {
  get moleculesList () {
    const list: Array<string> = []
    require.context(
      '../02-molecules/',
      false,
      /.vue$/
    ).keys().forEach((fileName: string) => {
      const componentName = fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
        .toLowerCase()
      list.push(componentName)
    })
    return list
  }
}
