
import { Options, Vue, prop } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import Button from '@/01-atoms/Button.vue'
import Separator from '@/02-molecules/Separator.vue'
import { format } from 'date-fns'
import checkMediaQuery from '@/helpers/media-query'

class Props {
  member = prop({
    type: Object,
    required: true,
    default: {
      id: '1ebf8d63-1fbf-6aa2-9db7-17330a959054',
      type: 'main',
      firstName: 'Marie-Sophie',
      lastName: 'Sistermann-BUNSCHEN',
      matricule: 'A-0000005',
      birthDate: '1990-12-12T00:00:00.000Z'
    }
  })
}

@Options({
  name: 'Family-member',
  components: {
    Tag,
    Button,
    Separator
  }
})

export default class FamilyMember extends Vue.with(Props) {
  get formattedBirthDate () {
    const birthdate = this.member['birthDate']
    if (birthdate) {
      return format(new Date(this.member['birthDate']), 'dd-MM-yyyy')
    }
    return '/'
  }

  get buttonUrl () {
    if (checkMediaQuery('(max-width: 1024px)')) {
      return `/family/member/${this.member.id}`
    } else {
      return `/family/member/${this.member.id}/personal-details`
    }
  }
}
