<template>
  <div class="trip-cancellation-form-uploads">
    <div class="ta-center">
      <h2 class="display-2">{{ $t('medial-form.title') }}</h2>
      <p class="mb-l grey-dark">{{ $t('medical-form.subtitle') }}</p>
      <br>
      <Button @click="requestForm('trip-cancellation-form-uploads-modal')" inverse>
        {{ $t('medical-form.request-button') }}
      </Button>
    </div>
  </div>
  <Modal modalId="trip-cancellation-form-uploads-modal">
    <template #title>{{ $t('medial-form.modal.title') }}</template>
    <div v-show="medicalFormStep === 1">
      <div class="mb-l">
        <InputLabel class="mb-m"><span class="fw-700 fs-20">1/2</span> {{ $t('medial-form.modal.cause') }}</InputLabel>
        <InputRadio v-for="item in tripCancellationReasonService.tripCancellationReasons"
                    group="cancellationReason"
                    :inputId="item.id"
                    :inputValue="item.id"
                    v-bind:key="item.id"
                    v-model="selectedTripCancellationReason"
                    class="mr-l">
          {{ item.name }}
        </InputRadio>
        </div>
      <div class="ta-center">
        <Button @click="medicalFormStep++" >{{ $t('global.next') }}</Button>
      </div>
    </div>
    <div v-show="medicalFormStep === 2">
      <div class="mb-l">
        <InputLabel class="mb-m"><span class="fw-700 fs-20">2/2</span>{{ $t('medial-form.modal.members') }}</InputLabel>
        <InputRadio v-for="item in memberService.members"
                    :inputId="item.id"
                    :inputValue="item.id"
                    v-bind:key="item.id"
                    class="mr-l"
                    group="familyMembers"
                    v-model="selectedMember">
          {{ item.firstName }}
        </InputRadio>
        </div>
      <div class="ta-center">
        <Button @click="medicalFormStep--" v-if="!isLoading" inverse>{{ $t('global.back') }}</Button>
        <Button @click="clickSubmitForm" v-if="!isLoading">{{ $t('global.next') }}</Button>
        <LoadingDots v-if="isLoading" />
      </div>
    </div>
    <div class="ta-center" v-show="medicalFormStep === 3">
      <div class="mb-l">
        <p class="fw-700 fs-20">{{ $t('medial-form.modal.end-title') }}</p>
        <p class="grey-dark">{{ $t('medial-form.modal.end-instructions') }}</p>
      </div>
      <div class="ta-center">
        <Button :href="downloadLink" :download="downloadTitle">{{ $t('medial-form.modal.end-instructions-link') }}</Button>
      </div>
    </div>
  </Modal>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Modal from '@/02-molecules/Modal.vue'
import InputRadio from '@/01-atoms/Input-radio.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputCheckbox from '@/01-atoms/Input-checkbox.vue'
import eventHub from '@/event-hub'
import { inject } from 'vue'
import { ErrorHandlerKey, MemberServiceKey, TripCancellationReasonServiceKey } from '@/services/serviceTypes'
import { PostTripCancellationForm, postTripCancellationForm } from '@/api/documents/documents-api'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Trip-cancellation-form-uploads',
  components: {
    LoadingDots,
    Button,
    Modal,
    InputRadio,
    InputLabel,
    InputCheckbox
  }
})

export default class TripCancellationFormUploads extends Vue {
  medicalFormStep = 1

  memberService = inject(MemberServiceKey)
  tripCancellationReasonService = inject(TripCancellationReasonServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  isLoading = false

  selectedMember = ''
  selectedTripCancellationReason = ''

  downloadLink = ''
  downloadTitle = ''

  beforeMount () {
    this.loadMembers()
    this.loadTripCancellationReasons()
  }

  loadMembers () {
    this.memberService?.loadMembers()
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  loadTripCancellationReasons () {
    this.tripCancellationReasonService?.loadTripCancellationReasons()
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  clickSubmitForm () {
    this.isLoading = true
    this.createTripCancellation()
  }

  createTripCancellation () {
    const tripCancellation: PostTripCancellationForm = {
      'memberId': this.selectedMember,
      'cancellationReason': this.selectedTripCancellationReason
    }
    this.submitTripCancellation(tripCancellation)
  }

  submitTripCancellation (tripCancellation: PostTripCancellationForm) {
    postTripCancellationForm(tripCancellation)
      .then((response) => {
        this.isLoading = false
        this.medicalFormStep++
        this.downloadLink = `data:${response.data.mimeType};base64, ${response.data.content}`
        this.downloadTitle = response.data.fileName
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  requestForm (id: string) {
    eventHub.$emit('open-modal', id)
  }

  resetModal () {
    this.medicalFormStep = 1
    eventHub.$emit('close-modal')
  }
}
</script>
<style lang="scss" scoped>
.trip-cancellation-form-uploads {
  border-radius: 10px;
  background-color: $blue-dark-10;
  padding: 33px 40px 45px;
  text-align: center;
}

.trip-cancellation-form-uploads__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: $xmedium;
  grid-row-gap: $medium;
}
</style>
