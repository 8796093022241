<template>
  <div class="card-edit">
    <div class="card-edit__header mb-m">
      <div class="card-edit__header__title fs-12 tt-uppercase">
        <span class="card-edit__header__title__label">
          <slot name="title">#title</slot>
        </span>
      </div>
      <div v-if="!isEditable">
        <IconButton
            round
            icon="edit-2"
            aria-label="edit card"
            @click="clickEditButton"
            background="blue-dark-10"
        />
      </div>
      <div class="d-f" v-else>
        <IconButton
            round
            class="mr-m"
            icon="check"
            @click="saveChanges"
            background="green-15"
            aria-label="save changes"
        />
        <IconButton
            round
            icon="x"
            @click="discardChanges"
            background="red-15"
            aria-label="discard changes"
        />
      </div>
<!--      <div class="card-edit__saved green fs-12" v-if="showNotification">Settings saved!</div>-->
    </div>
    <div v-if="$slots.center">
      <slot name="center"></slot>
    </div>
    <div v-else class="card-edit__grid">
      <div>
        <slot name="left"></slot>
      </div>
      <div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'

class Props {
  id!: string
}

@Options({
  name: 'Card-edit',
  components: {
    IconButton
  },
  emits: ['edit-card', 'save-card', 'discard-card']
})

/**
 * White Card with table layout and edit option
 *
 * @description {string} template#title
 * @description {HTML} template#left - Left side in 50/50 grid
 * @description {HTML} template#right - Ride side in 50/50 grid
 * @description {HTML=} template#cemter - Center 100 grid
 */
export default class CardEdit extends Vue.with(Props) {
  isEditable = false
  showNotification = false

  clickEditButton () {
    this.isEditable = true
    this.$emit('edit-card', this.id)
  }

  saveChanges () {
    this.isEditable = false
    setTimeout(() => {
      this.displayNotification()
    }, 500)
    this.$emit('save-card', this.id)
  }

  discardChanges () {
    this.isEditable = false
    this.$emit('discard-card', this.id)
  }

  displayNotification () {
    this.showNotification = true

    setTimeout(() => {
      this.hideNotification()
    }, 2000)
  }

  hideNotification () {
    this.showNotification = false
  }
}
</script>
<style lang="scss" scoped>
.card-edit {
  @include white-box;
  padding: 16px 22px 20px;
}

.card-edit__header {
  display: flex;
  flex-direction: row;
  position: relative;
}

.card-edit__header__title {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;

  &::after {
    content: '';
    background-color: $blue-dark-10;
    display: inline-block;
    height: 2px;
    margin: 0 10px 0 32px;
    position: relative;
    width: 100%;
  }
}

.card-edit__header__title__label {
  flex-shrink: 0;
}

.card-edit__grid {
  display: grid;
  grid-column-gap: $xmedium;
  grid-row-gap: $medium;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.card-edit__saved {
  background-color: transparent;
  position: absolute;
  right: 0;
  bottom: -15px;
}
</style>
