<template>
  <router-link :to="{name: 'Chat', params: { id: item.id }}" class="support-inbox-chat-preview">
    <div class="d-f">
<!--      <Icon icon="eye" :notifications="item.hasNewMessage ? 1 : 0" class="mr-m blue-dark"></Icon>-->
      <Icon icon="message-square" class="mr-m blue-dark"></Icon>
      <div class="support-inbox-chat-preview__text">
        <span v-if="topic" class="fw-700 fs-16 d-b blue-dark">{{ topic }}</span>
        <span v-if="item.lastMessage" class="fs-12 grey-dark">
          <span v-if="!item.lastMessage.isFromCm">You: </span>
          <span v-if="!item.lastMessage.content.length">File sent</span>
          {{ truncate(item.lastMessage.content, 40) }}
        </span>
      </div>
    </div>
    <span class="fs-14 grey-dark" v-if="item.lastMessage.createdAt">{{ lastMessageCreatedAt }}</span>
  </router-link>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import { Ticket } from '@/services/support/Ticket-service'
import mixins from '@/mixins'

class Props {
  item!: Ticket;
}

@Options({
  name: 'Support-inbox-chat-preview',
  components: {
    Icon
  }
})

export default class SupportInboxChatPreview extends Vue.with(Props) {
  get topic () {
    return this.item['topic'].topic
  }

  truncate (text: string, length: number) {
    if (text.length > length) {
      return text.substring(0, length) + '...'
    } else {
      return text
    }
  }

  get lastMessageCreatedAt () {
    const lastMessageCreatedAt = this.item['lastMessage'].createdAt!
    return mixins.methods.formatDate(lastMessageCreatedAt)
  }
}
</script>

<style lang="scss" scoped>
.support-inbox-chat-preview {
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
}

.support-inbox-chat-preview__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.router-link-exact-active {
  background: $grey-dark-10;
}
</style>
