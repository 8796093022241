<template>
    <div class="attachment">
      <Icon icon="file-text" class="mr-s"></Icon>
      {{ item.fileName }}
      <IconButton @click="removeAttachment" class="attachment__remove-button" icon="x" round />
    </div>
</template>
<script lang="ts">
import { Options, prop, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  item = prop({
    type: Object,
    required: true,
    default: {
      fileName: 'file.pdf'
    }
  })
}

@Options({
  name: 'Attachment',
  components: {
    Icon,
    IconButton
  },
  emits: ['remove-attachment']
})

/**
 * Attachment file box with remove button
 *
 * @param {string} attachedFileName
 */
export default class Attachment extends Vue.with(Props) {
  removeAttachment () {
    this.$emit('remove-attachment', this.item['id'])
  }
}
</script>
<style lang="scss" scoped>

.attachment {
  background-color: $blue-dark-10;
  border-radius: 15px;
  color: $grey-dark;
  display: flex;
  align-items: center;
  padding: 5px 15px 5px 10px;
  position: relative;
}

.attachment__remove-button.button.round {
  height: 18px;
  padding: 1px;
  position: absolute;
  top: -8px;
  right: -8px;
  width: 18px;
}
</style>
