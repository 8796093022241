
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import QrCode from '@/02-molecules/Qr-code.vue'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import VuePdfEmbed from 'vue-pdf-embed'

class Props {
  item!: Upload
}

@Options({
  name: 'Cns-upload',
  components: {
    Button,
    IconButton,
    QrCode,
    VuePdfEmbed
  },
  emits: ['remove-new-cns-upload']
})
export default class CnsUpload extends Vue.with(Props) {
  isPdf () {
    if (this.item['file'].includes('application/pdf')) {
      return true
    } else if (this.item['file'].includes('blob')) {
      return this.item['title'].includes('.pdf')
    }
  }

  removeUpload () {
    if (this.item) {
      this.$emit('remove-new-cns-upload', this.item.id)
    }
  }
}
