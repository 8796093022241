
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  direction?: string
}

@Options({
  name: 'Chevron-button',
  components: {
    Icon
  }
})

/**
 *
 * Button with chevron on the right
 *
 * @param {string=} direction - change direction of chevron (default: right, down)
 *
 */
export default class ChevronButton extends Vue.with(Props) {
  get iconClass () {
    let iconClass = 'chevron-button__icon'
    if (this.direction === 'down') {
      iconClass += ' chevron-button__icon--down'
    }
    return iconClass
  }
}
