<template>
  <div>
    <div class="mb-m ta-center"><Tag :variant="file.status" /></div>
    <div class="inquiry-upload">
      <vue-pdf-embed :page="1" v-if="isPdf()" :source="item.imageContent" class="inquiry-upload__image" />
      <img v-else class="inquiry-upload__image" :src="item.imageContent" :alt="item.fileName" />
      <IconButton @click="previewItem()" class="inquiry-upload__zoom-button" background="blue-dark" icon="zoom-in" round />
      <Button v-if="file.action === 'retake_photo'"
              @click="$router.push({ name: 'Edit inquiry', params: { id: inquiryId } })"
              class="inquiry-upload__action-button"
              lowercase
              inverse>
        {{ $t('inquiry.upload-button') }}
      </Button>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import VuePdfEmbed from 'vue-pdf-embed'

class Props {
  item!: object
  file?: object
  inquiryId?: string
}

@Options({
  name: 'Inquiry-upload',
  components: {
    Tag,
    Icon,
    Button,
    IconButton,
    VuePdfEmbed
  },
  emits: ['show-uploads-lightbox']
})
export default class InquiryUpload extends Vue.with(Props) {
  previewItem () {
    if (this.isPdf()) {
      this.openPdf()
    } else {
      this.showUploadsLightbox()
    }
  }

  openPdf () {
    const pdfWindow = window.open('')
    pdfWindow!.document.write(`<iframe width='100%' height='100%' src='${this.item['imageContent']}'></iframe>`)
  }

  isPdf () {
    return this.item['imageContent'].includes('application/pdf')
  }

  showUploadsLightbox () {
    this.$emit('show-uploads-lightbox')
  }
}
</script>
<style lang="scss" scoped>
.inquiry-upload {
  border-radius: 10px;
  border: 4px dashed $blue-dark-10;
  position: relative;
  width: 100%;
  padding-top: 128.5%;
}

.inquiry-upload__zoom-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.inquiry-upload__action-button {
  border: 2px solid $blue-dark-10;
  min-width: 70%;
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  @include breakpoint(medium down) {
    padding: 8px 10px;
  }
}

.inquiry-upload__image {
  @include object-fit();
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  top: 0;
}
</style>
