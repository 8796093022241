<template>
  <Modal modalId="new-message">
    <template #title>{{ $t('support.new-message.title') }}</template>
    <form :class="errors ? 'form has-errors' : false" ref="form">
      <div class="new-message-modal mb-l">
        <div>
          <InputLabel for="topic" small>{{ $t('support.new-message.topic') }}</InputLabel>
          <InputSelect
              required
              id="topic"
              :options="topics"
              ref="messageTopic"
              @invalid="invalidateForm"
              :placeholder="$t('support.new-message.topic-placeholder')"
              @select-option="selectTopic"
          />
        </div>
        <div>
          <InputLabel for="members" small>{{ $t('support.new-message.member') }}</InputLabel>
          <InputSelect
              required
              id="members"
              :options="members"
              ref="messageMember"
              @invalid="invalidateForm"
              :placeholder="$t('support.new-message.member-placeholder')"
              @select-option="selectMember"
          />
        </div>
      </div>
      <InputLabel for="message" class="mb-s" small>{{ $t('support.new-message.message') }}</InputLabel>
      <textarea
          required
          rows="10"
          cols="30"
          id="message"
          ref="messageContent"
          @invalid="invalidateForm"
          class="new-message__textarea mb-m"
          :placeholder="$t('support.new-message.message-placeholder')"
      />
      <InputUpload @add-attachment="addAttachment" uploadId="document-upload" class="mb-l">
        {{ $t('support.new-message.document-upload') }}
      </InputUpload>
      <div class="ta-center">
        <LoadingDots v-if="isLoading" />
        <Button v-else @click="submit($event)" type="submit">{{ $t('global.submit') }}</Button>
      </div>
    </form>
  </Modal>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Modal from '@/02-molecules/Modal.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputSelect, { SelectOption } from '@/01-atoms/Input-select.vue'
import InputUpload from '@/01-atoms/Input-upload.vue'
import Button from '@/01-atoms/Button.vue'
import eventHub from '@/event-hub'
import router from '@/router'
import { inject } from 'vue'
import { ErrorHandlerKey, MemberServiceKey, TicketServiceKey, TopicServiceKey } from '@/services/serviceTypes'
import { Topic } from '@/services/support/Topic-service'
import { Member } from '@/services/family/MemberService'
import { AttachmentPreview, NewTicket } from '@/services/support/Ticket-service'
import { postSupportTicket } from '@/api/support/tickets-api'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'New-message-modal',
  components: {
    LoadingDots,
    Modal,
    InputLabel,
    InputSelect,
    InputUpload,
    Button
  }
})
export default class NewMessageModal extends Vue {
  errors = false
  selectedTopicId = ''
  selectedMemberId = ''

  topics = [] as Array<SelectOption>
  members = [] as Array<SelectOption>

  topicService = inject(TopicServiceKey)
  memberService = inject(MemberServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  isLoading = false

  beforeMount () {
    this.loadTopics()
    this.loadMembers()
  }

  mounted () {
    eventHub.$on('set-textarea', (content: string) => this.setTextAreaValue(content))
  }

  loadTopics () {
    this.topicService?.loadTopics()
      .then(() => {
        this.convertTopicsForSelect()
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  convertTopicsForSelect () {
    this.topicService?.topics.forEach((topic: Topic) => {
      const newItem: SelectOption = {
        id: topic.id,
        title: topic.topic
      }
      this.topics.push(newItem)
    })
  }

  loadMembers () {
    this.memberService?.loadMembers()
        .then(() => {
          this.convertMembersForSelect()
        })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  convertMembersForSelect () {
    this.memberService?.members.forEach((member: Member) => {
      const newItem: SelectOption = {
        id: member.id,
        title: member.firstName
      }
      this.members.push(newItem)
    })
  }

  attachment = {
    fileName: '',
    fileId: '',
    file: ''
  } as AttachmentPreview

  submit (ev: Event) {
    ev.preventDefault()
    const form: any = this.$refs['form']
    if (form.checkValidity()) {
      this.isLoading = true
      this.sendTicket()
    }
  }

  ticketService = inject(TicketServiceKey)

  sendTicket () {
    const ticket: NewTicket = this.composeTicket()
    postSupportTicket(ticket)
      .then((response) => {
        this.isLoading = false
        this.ticketService?.addNewTicket()

        eventHub.$emit('close-modal')

        const ticketId = response.data.id
        this.redirectToTicket(ticketId)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  redirectToTicket (ticketId: string) {
    router.push(`/support/inbox/${ticketId}`)
    this.resetModal()
  }

  resetModal () {
    const textarea: any = this.$refs['messageContent']
    textarea.value = ''
    eventHub.$emit('reset-select')
    eventHub.$emit('close-modal')
  }

  composeTicket () {
    const textarea: any = this.$refs['messageContent']
    const messageContent = textarea.value
    const message: NewTicket = {
      topicId: this.selectedTopicId,
      concernedMemberId: this.selectedMemberId,
      message: {
        content: messageContent
      }
    }

    if (this.attachment.fileId) {
      message.message['attachedFile'] = this.attachment.fileId
    }

    return message
  }

  addAttachment (attachment: AttachmentPreview) {
    this.attachment = attachment
  }

  invalidateForm () {
    this.errors = true
  }

  selectTopic (id: string) {
    this.selectedTopicId = id
  }

  selectMember (id: string) {
    this.selectedMemberId = id
  }

  setTextAreaValue (content: string) {
    eventHub.$emit('reset-select')
    const textarea: any = this.$refs['messageContent']
    textarea.value = content
  }
}
</script>
<style lang="scss" scoped>
.new-message-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $xmedium;

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
    grid-gap: $medium;
  }
}

.new-message__textarea {
  border: 0;
  resize: none;
  width: 100%;

  .has-errors &:invalid {
    border: 1px solid $red;
  }
}
</style>
