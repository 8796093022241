
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import { Ticket } from '@/services/support/Ticket-service'
import mixins from '@/mixins'

class Props {
  item!: Ticket;
}

@Options({
  name: 'Support-inbox-chat-preview',
  components: {
    Icon
  }
})

export default class SupportInboxChatPreview extends Vue.with(Props) {
  get topic () {
    return this.item['topic'].topic
  }

  truncate (text: string, length: number) {
    if (text.length > length) {
      return text.substring(0, length) + '...'
    } else {
      return text
    }
  }

  get lastMessageCreatedAt () {
    const lastMessageCreatedAt = this.item['lastMessage'].createdAt!
    return mixins.methods.formatDate(lastMessageCreatedAt)
  }
}
