
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Icon from '@/01-atoms/Icon.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import Tag from '@/01-atoms/Tag.vue'
import InputSelect from '@/01-atoms/Input-select.vue'
import InputText from '@/01-atoms/Input-text.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import statusFactory from '@/helpers/status-factory'

@Options({
  components: {
    Button,
    Tag,
    IconButton,
    Icon,
    InputSelect,
    InputText,
    BackButton
  },
  methods: { statusFactory },
  data: () => ({
    // Button
    buttonInclude: 'import Button from \'@/00-atoms/Button.vue\'',
    buttonCode1: '<Button>Default</Button>',
    buttonCode2: '<Button inverse>Inverse</Button>',
    buttonCode3: '<Button lowercase>lowercase</Button>',
    buttonCode4: '<Button href="#">Link</Button>',
    buttonCode5: '<Button to="/" >Router Link</Button>',
    buttonCode6: '<Button disabled>Disabled</Button>',
    // Icon
    iconInclude: 'import Icon from \'@/00-atoms/Icon.vue\'',
    iconCode1: '<Icon icon="home" />',
    iconCode2: '<Icon icon="home" color="green" />',
    iconCode3: '<Icon icon="home" background="blue-dark" />',
    iconCode4: '<Icon icon="bell" notifications="1" />',
    iconCode5: '<Icon icon="bell" notifications="2" />',
    // IconButton
    iconButtonInclude: 'import IconButton from \'@/00-atoms/Icon-button.vue\'',
    iconButton1: '<IconButton icon="home">Default</IconButton>',
    iconButton2: '<IconButton background="blue-dark-10" icon="home">Color</IconButton>',
    iconButton3: '<IconButton background="transparent" icon="home">Transparent</IconButton>',
    iconButton4: '<IconButton icon="home" lowercase>lowercase</IconButton>',
    iconButton5: '<IconButton icon="home" small>small</IconButton>',
    iconButton6: '<IconButton icon="home" medium>medium</IconButton>',
    iconButton7: '<IconButton icon="document" notifications="1" background="transparent">Notification</IconButton>',
    // Tag
    tagInclude: 'import Tag from \'@/00-atoms/Tag.vue\'',
    tagCodeVariant1: '<Tag :variant="{variable}" />',
    tagCodeVariant2: '<Tag :variant="{variable}" responsive />'
  })
})
export default class Atoms extends Vue {
  get iconList () {
    const list: Array<string> = []
    require.context(
      '../assets/icons',
      false,
      /.vue$/
    ).keys().forEach((fileName: string) => {
      const componentName = fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
        .toLowerCase()
      list.push(componentName)
    })
    return list
  }
}
