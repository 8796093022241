
import { Options, Vue } from 'vue-class-component'

class Props {
  checked?: string
  group?: string
  inputId!: string
  required?: string
  modelValue?: string
}

@Options({
  name: 'Input-radio',
  components: {
  },
  emits: ['change-radio-input', 'update:modelValue']
})

/**
 * Radio type input followed by a label
 *
 * @param {string} inputId - ID to connect input and label
 * @param {string=} group - Name to group radio buttons
 * @param {attribute=} checked
 * @param {attribute=} required
 */
export default class InputRadio extends Vue.with(Props) {
  updateRadio () {
    this.$emit('change-radio-input', this.inputId)
  }
}
