<template>
  <button class="chevron-button fw-700 blue-dark">
    <slot>Chevron Button</slot>
    <Icon icon="chevron-right" :class="iconClass" background="transparent" round></Icon>
  </button>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  direction?: string
}

@Options({
  name: 'Chevron-button',
  components: {
    Icon
  }
})

/**
 *
 * Button with chevron on the right
 *
 * @param {string=} direction - change direction of chevron (default: right, down)
 *
 */
export default class ChevronButton extends Vue.with(Props) {
  get iconClass () {
    let iconClass = 'chevron-button__icon'
    if (this.direction === 'down') {
      iconClass += ' chevron-button__icon--down'
    }
    return iconClass
  }
}
</script>
<style lang="scss" scoped>

.chevron-button {
  @include focus;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
  width: 100%;
}

.chevron-button__icon {
  transition: transform 0.2s ease;
}

.chevron-button__icon--down {
  transform: rotate(90deg);
}
</style>
