
import { Options, Vue } from 'vue-class-component'
import BackButton from '@/02-molecules/Back-button.vue'
import Button from '@/01-atoms/Button.vue'
import Chat from '@/04-pages/06-Support/Support-inbox--chat.vue'
import ChatMessage from '@/02-molecules/Chat-message.vue'
import Confirmation from '@/03-organisms/Confirmation.vue'
import CnsUploads from '@/03-organisms/Cns-uploads.vue'
import ContentBox from '@/03-organisms/Content-box.vue'
import FamilyMember from '@/03-organisms/Family-member.vue'
import FamilyMemberCard from '@/03-organisms/Family-member-card.vue'
import HeaderSearch from '@/03-organisms/Header-search.vue'
import NewInquiryUploads from '@/03-organisms/New-inquiry-uploads.vue'
import NewMessageModal from '@/03-organisms/New-message-modal.vue'
import PackagePreview from '@/03-organisms/Package-preview.vue'
import QuickInquiry from '@/03-organisms/Quick-inquiry.vue'
import QuickUpload from '@/03-organisms/Quick-upload.vue'
import SettingCard from '@/03-organisms/Setting-card.vue'
import Slider from '@/03-organisms/Slider.vue'
import TripCancellationFormUploads from '@/03-organisms/Trip-cancellation-form-uploads.vue'

@Options({
  components: {
    BackButton,
    Button,
    Chat,
    ChatMessage,
    Confirmation,
    CnsUploads,
    ContentBox,
    FamilyMember,
    FamilyMemberCard,
    HeaderSearch,
    NewInquiryUploads,
    NewMessageModal,
    PackagePreview,
    QuickInquiry,
    QuickUpload,
    SettingCard,
    Slider,
    TripCancellationFormUploads
  }
})
export default class Organisms extends Vue {
  get organismsList () {
    const list: Array<string> = []
    require.context(
      '../03-organisms/',
      false,
      /.vue$/
    ).keys().forEach((fileName: string) => {
      const componentName = fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
        .toLowerCase()
      if (componentName === 'navigation' || componentName === 'topbar' || componentName === 'modal-template') {
        // skip
      } else {
        list.push(componentName)
      }
    })
    return list
  }
}
