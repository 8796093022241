
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  copy?: string
}

@Options({
  name: 'Copy-button',
  components: {
    Icon
  }
})

/**
 * Copy to clipboard
 *
 * @param {string=} copy - Value to be copied to
 */
export default class CopyButton extends Vue.with(Props) {
  showSuccess = false
  onCopySuccess () {
    this.showSuccess = true

    setTimeout(() => {
      this.showSuccess = false
    }, 1000)
  }

  get copyValue () {
    if (this.copy !== undefined) {
      return this.copy
    } else {
      return this.$slots.default!()[0].children
    }
  }
}
