<template>
  <h1 class="fs-24 ta-center modal-template__title"><slot name="title">Title</slot></h1>
  <p class="fs-14 fw-700 ta-center mb-xl grey-dark"><slot name="subtitle">Subtitle</slot></p>
  <div class="modal-template__form">
    <slot name="content"></slot>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Modal-template',
  components: {

  }
})
export default class ModalTemplate extends Vue { }
</script>
<style lang="scss" scoped>
.modal-template__title {
  margin-top: 0;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;

  &::after {
    content: "";
    background-color: $blue-dark-10;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.modal-template__form {
  margin: 0 auto;
  max-width: 350px;
}
</style>
