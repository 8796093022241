<template>
  <span>
    <input
        :id="inputId"
        type="checkbox"
        :value="hasInputValue ? inputValue : null"
        @change="updateCheckbox"
        class="input-checkbox__input"
        :required="required === '' ? 'required' : false"
        @input="$emit('update:modelValue', $event.target.value)"
    >
    <label class="input-checkbox__label grey-dark fs-16" :for="inputId"><slot>Option</slot></label>
  </span>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

class Props {
  inputId!: string
  required?: string
  modelValue?: string
  inputValue?: string
}
@Options({
  name: 'Input-checkbox',
  components: {
  },
  emits: ['change-checkbox-input', 'update:modelValue']
})

/**
 * @param {string} inputId
 * @param {attribute=} required
 */
export default class InputCheckbox extends Vue.with(Props) {
  hasInputValue = false

  updateCheckbox () {
    this.$emit('change-checkbox-input', this.inputId)
  }

  mounted () {
    this.hasInputValue = !!this.inputValue
  }
}
</script>
<style lang="scss" scoped>
.input-checkbox__input {
  position: absolute;
  opacity: 0;
}

.input-checkbox__label {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  user-select: none;
}

.input-checkbox__input + .input-checkbox__label::before {
  content: '';
  border: 1px solid $grey-dark;
  border-radius: 2px;
  display: inline-block;
  height: 17px;
  flex-shrink: 0;
  position: relative;
  top: 1px;
  margin-right: 12px;
  width: 17px;
}

.input-checkbox__input:checked + .input-checkbox__label::after {
  content: '';
  background-color: $grey-dark;
  border-radius: 2px;
  display: inline-block;
  height: 11px;
  margin-right: 12px;
  position: absolute;
  top: 4px;
  left: 3px;
  width: 11px;
}

.form.has-errors {
  .input-checkbox__input:invalid + .input-checkbox__label::before {
    border: 1px solid $red;
  }
}
</style>
