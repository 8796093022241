
import { Options, Vue } from 'vue-class-component'

/**
 * White Card
 *
 * @description {string} template#title
 * @description {HTML} template
 */

@Options({
  name: 'Card'
})

export default class Card extends Vue { }
