
import { Options, Vue } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import VuePdfEmbed from 'vue-pdf-embed'

class Props {
  item!: object
  file?: object
  inquiryId?: string
}

@Options({
  name: 'Inquiry-upload',
  components: {
    Tag,
    Icon,
    Button,
    IconButton,
    VuePdfEmbed
  },
  emits: ['show-uploads-lightbox']
})
export default class InquiryUpload extends Vue.with(Props) {
  previewItem () {
    if (this.isPdf()) {
      this.openPdf()
    } else {
      this.showUploadsLightbox()
    }
  }

  openPdf () {
    const pdfWindow = window.open('')
    pdfWindow!.document.write(`<iframe width='100%' height='100%' src='${this.item['imageContent']}'></iframe>`)
  }

  isPdf () {
    return this.item['imageContent'].includes('application/pdf')
  }

  showUploadsLightbox () {
    this.$emit('show-uploads-lightbox')
  }
}
