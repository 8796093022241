
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'

class Props {
  id!: string
}

@Options({
  name: 'Card-edit',
  components: {
    IconButton
  },
  emits: ['edit-card', 'save-card', 'discard-card']
})

/**
 * White Card with table layout and edit option
 *
 * @description {string} template#title
 * @description {HTML} template#left - Left side in 50/50 grid
 * @description {HTML} template#right - Ride side in 50/50 grid
 * @description {HTML=} template#cemter - Center 100 grid
 */
export default class CardEdit extends Vue.with(Props) {
  isEditable = false
  showNotification = false

  clickEditButton () {
    this.isEditable = true
    this.$emit('edit-card', this.id)
  }

  saveChanges () {
    this.isEditable = false
    setTimeout(() => {
      this.displayNotification()
    }, 500)
    this.$emit('save-card', this.id)
  }

  discardChanges () {
    this.isEditable = false
    this.$emit('discard-card', this.id)
  }

  displayNotification () {
    this.showNotification = true

    setTimeout(() => {
      this.hideNotification()
    }, 2000)
  }

  hideNotification () {
    this.showNotification = false
  }
}
