<template>
  <div>
    <IconButton class="help-toggle__button" @click="toggleHelp" background="transparent" icon="question-circle" lowercase>
      <slot name="title">#title</slot>
    </IconButton>
    <div v-if="isToggled">
      <p class="fs-14 grey-dark mt-0">
        <slot>template</slot>
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'

@Options({
  name: 'Help-toggle',
  components: {
    IconButton
  }
})

/**
 * @description {string} template#title
 * @description {string} template
 */
export default class HelpToggle extends Vue {
  isToggled = false

  toggleHelp () {
    if (this.isToggled) {
      this.isToggled = false
    } else {
      this.isToggled = true
    }
  }
}
</script>
<style lang="scss" scoped>
.help-toggle__button {
  @include focus;
}
</style>
