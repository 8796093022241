
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'

@Options({
  name: 'Help-toggle',
  components: {
    IconButton
  }
})

/**
 * @description {string} template#title
 * @description {string} template
 */
export default class HelpToggle extends Vue {
  isToggled = false

  toggleHelp () {
    if (this.isToggled) {
      this.isToggled = false
    } else {
      this.isToggled = true
    }
  }
}
