<template>
  <div class="cns-upload mb-s ta-center">
    <div class="cns-upload__media">
      <vue-pdf-embed :page="1" class="cns-upload__image" v-if="isPdf()" :source="item.file" />
      <img v-else class="cns-upload__image" :src="item.file" :alt="item.title">
      <IconButton @click="removeUpload" class="cns-upload__delete" icon="x-circle" background="transparent" round></IconButton>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import QrCode from '@/02-molecules/Qr-code.vue'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import VuePdfEmbed from 'vue-pdf-embed'

class Props {
  item!: Upload
}

@Options({
  name: 'Cns-upload',
  components: {
    Button,
    IconButton,
    QrCode,
    VuePdfEmbed
  },
  emits: ['remove-new-cns-upload']
})
export default class CnsUpload extends Vue.with(Props) {
  isPdf () {
    if (this.item['file'].includes('application/pdf')) {
      return true
    } else if (this.item['file'].includes('blob')) {
      return this.item['title'].includes('.pdf')
    }
  }

  removeUpload () {
    if (this.item) {
      this.$emit('remove-new-cns-upload', this.item.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.cns-upload {
  border: 4px dashed $blue-dark;
  border-radius: 10px;
  height: 360px;
  margin-right: -30px;
  margin-left: -30px;

  @include breakpoint(large down) {
    height: auto;
    max-height: 360px;
  }
}

.cns-upload__image {
  @include object-fit;
  border-radius: 10px;
  overflow: hidden;
}

.cns-upload__delete {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.cns-upload__media {
  height: 100%;
  position: relative;
}
</style>
