<template>
  <ChevronButton @click="toggleAccordion()" :direction="showAccordion && 'down'">
    <slot name="title">#title</slot>
  </ChevronButton>
  <div class="grey-dark accordion__description" v-if="showAccordion">
    <slot>template</slot>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ChevronButton from '@/01-atoms/Chevron-button.vue'

@Options({
  name: 'Accordion',
  components: {
    ChevronButton
  }
})

/**
 *
 * Accordion with chevron button template
 *
 * @description {string} template#title
 * @description {HTML} template
 *
 */
export default class Accordion extends Vue {
  showAccordion = false

  toggleAccordion () {
    if (this.showAccordion) {
      this.showAccordion = false
    } else {
      this.showAccordion = true
    }
  }
}
</script>
<style lang="scss" scoped>
.accordion__description {
  padding-top: $small;
  padding-right: 100px;

  @include breakpoint(medium down) {
    padding-right: 0;
  }
}
</style>
