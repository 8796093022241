<template>
  <button
      v-clipboard:success="onCopySuccess"
      v-clipboard:copy="copyValue"
      class="d-f copy-button"
  >
    <span class="mr-m fw-700 grey-dark fs-16">{{ $slots.default()[0].children }}</span>
    <Icon color="grey-dark" icon="copy"></Icon>
    <span class="green fs-12 copy-button__success" v-if="showSuccess">Copied!</span>
  </button>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

class Props {
  copy?: string
}

@Options({
  name: 'Copy-button',
  components: {
    Icon
  }
})

/**
 * Copy to clipboard
 *
 * @param {string=} copy - Value to be copied to
 */
export default class CopyButton extends Vue.with(Props) {
  showSuccess = false
  onCopySuccess () {
    this.showSuccess = true

    setTimeout(() => {
      this.showSuccess = false
    }, 1000)
  }

  get copyValue () {
    if (this.copy !== undefined) {
      return this.copy
    } else {
      return this.$slots.default!()[0].children
    }
  }
}
</script>
<style lang="scss" scoped>
.copy-button {
  background-color: transparent;
  padding: 0;
  position: relative;
}

.copy-button__success {
  background-color: transparent;
  position: absolute;
  right: 0;
  bottom: -10px;
}
</style>
