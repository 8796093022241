<template>
  <div class="observer" ref="observer"></div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'observer',
  emits: ['is-intersecting']
})

export default class Observer extends Vue {
  observer: any

  mounted () {
    this.startObserver()
  }

  startObserver () {
    const options = {
      rootMargin: '0px',
      threshold: 1.0
    }

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        entry.isIntersecting && this.$emit('is-intersecting')
      })
    }, options)

    const target: any = this.$refs.observer
    this.observer.observe(target)
  }

  destroyed () {
    const target: any = this.$refs.observer
    this.observer.unobserve(target)
  }
}
</script>
<style lang="scss">
.observer {
  height: 1px;
  width: 100%;
}
</style>
