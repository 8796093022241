<template>
  <span class="input-radio">
    <input
        type="radio"
        :name="group"
        :id="inputId"
        @change="updateRadio"
        class="input-radio__input"
        :checked="checked === '' ? 'checked' : false"
        :required="required === '' ? 'required' : false"
        @input="$emit('update:modelValue', $event.target.id)"
    >
    <label class="input-radio__label fs-16" :for="inputId"><slot>Option</slot></label>
  </span>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

class Props {
  checked?: string
  group?: string
  inputId!: string
  required?: string
  modelValue?: string
}

@Options({
  name: 'Input-radio',
  components: {
  },
  emits: ['change-radio-input', 'update:modelValue']
})

/**
 * Radio type input followed by a label
 *
 * @param {string} inputId - ID to connect input and label
 * @param {string=} group - Name to group radio buttons
 * @param {attribute=} checked
 * @param {attribute=} required
 */
export default class InputRadio extends Vue.with(Props) {
  updateRadio () {
    this.$emit('change-radio-input', this.inputId)
  }
}
</script>
<style lang="scss" scoped>
.input-radio {
  display: inline-block;

  @include breakpoint(medium down) {
    display: block;
  }
}

.input-radio__input {
  position: absolute;
  opacity: 0;
}

.input-radio__label {
  color: $grey-dark;
  display: inline-flex;
  align-items: center;
  position: relative;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.input-radio__input + .input-radio__label::before {
  content: '';
  border: 1px solid $grey-dark;
  border-radius: 50%;
  display: inline-block;
  height: 18px;
  flex-shrink: 0;
  position: relative;
  top: 1px;
  margin-right: 12px;
  width: 18px;
}

.input-radio__input:checked + .input-radio__label::after {
  content: '';
  background-color: $grey-dark;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin-right: 12px;
  position: absolute;
  left: 3px;
  transform: translateY(1px);
  width: 12px;
}

.form.has-errors {
  .input-radio__input:invalid + .input-radio__label::before {
    border: 1px solid $red;
  }
}
</style>
