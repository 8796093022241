<template>
  <tr class="table-row" @click="clickEntry($event, item.id)">
    <td class="table__item-preview">
      <Icon class="mr-m xm-up" background="blue-dark" icon="file-text" />
      <div>
        <router-link :to="{ name: 'Document', params: { id: item.id } }" class="fs-16 fw-700 table__item-title">
          {{ item.title }}
        </router-link>
        <span v-if="item.documentType === 'document'" class="d-b grey-dark">Document</span>
        <span v-if="item.documentType === 'contract'" class="d-b grey-dark">Contract</span>
        <span v-if="item.documentType === 'domiciliation'" class="d-b grey-dark">Domiciliation</span>
      </div>
    </td>
    <td class="fs-14 grey-dark xl-up">{{ formatDate(item.submittedAt) }}</td>
    <td class="ta-center">
      <Tag v-if="item.status" :variant="item.status" responsive />
    </td>
    <td class="ta-center">
      <router-link v-if="actionFactory()['status']" :to="actionFactory()['route']">
        <Tag class="js-action" :icon="actionFactory()['icon']" :variant="actionFactory()['status']" responsive />
      </router-link>
    </td>
  </tr>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '../01-atoms/Icon-button.vue'
import Tag from '../01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import router from '@/router'
import { DocumentBase } from '@/services/documents/DocumentService'

class Props {
  item?: DocumentBase;
}

@Options({
  name: 'Inquiries-table-row',
  components: {
    Icon,
    Tag,
    IconButton
  }
})

export default class TableRowDocument extends Vue.with(Props) {
  clickEntry (ev: Event, id: string) {
    const target: any = ev.target
    if (!target['classList'].contains('js-action')) {
      router.push({ name: 'Document', params: { id: id } })
    }
  }

  actionFactory () {
    if (this.item) {
      const actionButton = {
        status: '',
        icon: '',
        route: {
          name: 'Document', params: { id: this.item.id }
        }
      }

      switch (this.item['status']) {
        case 'pending':
          if (this.item['documentType'] === 'invoice') {
            actionButton['status'] = 'pay_now'
            actionButton['icon'] = 'credit-card'
            actionButton['route'] = {
              name: 'Document payment', params: { id: this.item.id }
            }
          }
          break
        case 'to_be_renewed':
          actionButton['status'] = 'upload_now'
          actionButton['icon'] = 'upload'
          actionButton['route'] = {
            name: 'Document upload', params: { id: this.item.id }
          }
          break
      }

      return actionButton
    }
  }
}
</script>
