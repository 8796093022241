
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

@Options({
  name: 'Confirmation',
  components: {
    Icon
  }
})

/**
 *
 * white box with confirmation icon at the center
 *
 * @description {string} template#header
 * @description {string} template#title
 * @description {HTML} template
 */
export default class Confirmation extends Vue {
}
