<template>
  <h1 class="display-1 mb-m"><slot name="header">#header</slot></h1>
  <div class="confirmation__box ta-center">
    <h2 class="display-2 mb-xl confirmation__box__title"><slot name="title">#title</slot></h2>
    <div class="mb-xl">
      <Icon icon="check-circle" class="blue-dark confirmation__icon"></Icon>
    </div>
    <div class="grey-dark">
      <slot name="content">
        template
      </slot>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'

@Options({
  name: 'Confirmation',
  components: {
    Icon
  }
})

/**
 *
 * white box with confirmation icon at the center
 *
 * @description {string} template#header
 * @description {string} template#title
 * @description {HTML} template
 */
export default class Confirmation extends Vue {
}
</script>
<style lang="scss" scoped>
  .confirmation__box {
    @include white-box;
    padding: 30px;
    max-width: 720px;
  }

  .confirmation__box__title {
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
  }

  .confirmation__icon {
    height: 85px;
    margin-right: auto;
    margin-left: auto;
    width: 85px;
  }
</style>
