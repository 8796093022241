<template>
  <div :class="[member.status !== 'active'&& 'is-pending' , 'family-member', 'placeholder-animation']">
    <Tag class="mb-l" icon="user" :label="member.type" />
    <span class="display-3 family-member__name">
      {{ member.firstName }} {{ member.lastName }}
    </span>
    <div class="family-member__separator"></div>
    <span class="tt-uppercase fs-12 mb-s">{{ $t('family.member.cmcm-number')}}:</span>
    <span class="fw-700 mb-l">{{ member.matricule }}</span>
    <span class="tt-uppercase fs-12 mb-s">{{ $t('family.member.date-of-birth')}}:</span>
    <span class="fw-700 mb-m mb-xl">{{ formattedBirthDate }}</span>
    <Button v-if="member.status === 'active'" :to="buttonUrl" inverse>{{ $t('global.see-more')}}</Button>
    <Tag v-else variant="pending">{{ $t('global.pending')}}</Tag>
  </div>
</template>
<script lang="ts">
import { Options, Vue, prop } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import Button from '@/01-atoms/Button.vue'
import Separator from '@/02-molecules/Separator.vue'
import { format } from 'date-fns'
import checkMediaQuery from '@/helpers/media-query'

class Props {
  member = prop({
    type: Object,
    required: true,
    default: {
      id: '1ebf8d63-1fbf-6aa2-9db7-17330a959054',
      type: 'main',
      firstName: 'Marie-Sophie',
      lastName: 'Sistermann-BUNSCHEN',
      matricule: 'A-0000005',
      birthDate: '1990-12-12T00:00:00.000Z'
    }
  })
}

@Options({
  name: 'Family-member',
  components: {
    Tag,
    Button,
    Separator
  }
})

export default class FamilyMember extends Vue.with(Props) {
  get formattedBirthDate () {
    const birthdate = this.member['birthDate']
    if (birthdate) {
      return format(new Date(this.member['birthDate']), 'dd-MM-yyyy')
    }
    return '/'
  }

  get buttonUrl () {
    if (checkMediaQuery('(max-width: 1024px)')) {
      return `/family/member/${this.member.id}`
    } else {
      return `/family/member/${this.member.id}/personal-details`
    }
  }
}
</script>
<style lang="scss" scoped>
.family-member {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $blue-dark-10;
  border-radius: 10px;
  padding: 30px 50px;
  position: relative;

  @include breakpoint(medium down) {
    padding: 15px;
  }

  &.is-pending {
    opacity: 0.5;
  }
}

.family-member__separator {
  display: block;
  background: $blue-dark;
  height: 1px;
  margin: 26px 0;
  width: 100%;
}

.family-member__name {
  max-width: 75%;
  flex-grow: 1;
}
</style>
