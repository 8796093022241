
import { Options, Vue } from 'vue-class-component'

class Props {
  inputId!: string
  required?: string
  modelValue?: string
  inputValue?: string
}
@Options({
  name: 'Input-checkbox',
  components: {
  },
  emits: ['change-checkbox-input', 'update:modelValue']
})

/**
 * @param {string} inputId
 * @param {attribute=} required
 */
export default class InputCheckbox extends Vue.with(Props) {
  hasInputValue = false

  updateCheckbox () {
    this.$emit('change-checkbox-input', this.inputId)
  }

  mounted () {
    this.hasInputValue = !!this.inputValue
  }
}
