<template>
  <div class="document-upload">
    <img class="document-upload__image" :src="item.imageContent" :alt="item.fileName" />
    <IconButton
        round
        icon="zoom-in"
        aria-label="zoom in"
        background="blue-dark"
        @click="showUploadsLightbox"
        class="document-upload__zoom-button"
    />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Tag from '@/01-atoms/Tag.vue'
import Icon from '@/01-atoms/Icon.vue'
import Button from '@/01-atoms/Button.vue'
import IconButton from '@/01-atoms/Icon-button.vue'

class Props {
  item!: object
}

@Options({
  name: 'Document-upload',
  components: {
    Tag,
    Icon,
    Button,
    IconButton
  },
  emits: ['show-uploads-lightbox']
})
export default class DocumentUpload extends Vue.with(Props) {
  showUploadsLightbox () {
    this.$emit('show-uploads-lightbox')
  }
}
</script>
<style lang="scss" scoped>

.document-upload {
  border-radius: 10px;
  border: 4px dashed $blue-dark-10;
  padding-top: 128.5%;
  position: relative;
  width: 100%;
}

.document-upload__image {
  @include object-fit();
  position: absolute;
  top: 0;
  border-radius: 10px;
  margin: 0 auto;
}

.document-upload__zoom-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.document-upload__zoom-modal {
  @include loader;
  // hack to recreate same shade of grey as modal zoom in
  background-image: linear-gradient(transparentize($grey-dark, 0.3), transparentize($grey-dark, 0.3)),linear-gradient(transparentize($blue-dark, 0.75), transparentize($blue-dark, 0.75));
  display: none;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  &.is-active {
    display: flex;
  }
}

.document-upload__zoom-modal__image {
  position: relative;
  max-height: 100%;
  z-index: 2;
}
</style>
