<template>
  <Modal modalId="cns-uploads-modal">
    <template #title>{{ $t('new-inquiry.cns-upload.modal.title') }}</template>
    <div class="cns-uploads__grid mb-l" v-if="previousCnsUploads.length">
      <CnsUploadSelect @change-checkbox-input="updatePreviousCnsUploadSelect" v-for="item in previousCnsUploads" v-bind:key="item.id" :item="item" />
    </div>
    <div v-else class="mb-l ta-center">
      <span class="display-3">{{ $t('new-inquiry.cns-upload.modal.empty-state')}}}</span>
    </div>
    <template #footer>
      <div class="ta-center">
        <Button @click="addPreviousCnsUploads" v-if="previousCnsUploads.length">{{ $t('new-inquiry.cns-upload.modal.select') }}</Button>
        <Button @click="closeModal" v-else>{{ $t('global.close') }}</Button>
      </div>
    </template>
  </Modal>
  <div class="cns-uploads ta-center">
    <h2 class="display-2 mt-0">{{ $t('new-inquiry.cns-upload.title') }}</h2>
    <p class="mb-l grey-dark">{{ $t('new-inquiry.cns-upload.upload-text') }}</p>
    <CnsUpload
        :item="item"
        v-bind:key="item.id"
        v-for="item in newCnsUploads"
        @remove-new-cns-upload="removeNewCnsUpload"
    />
    <div v-if="showUploadForm" class="cns-upload mb-s ta-center">
      <div class="cns-upload__inner">
        <QrCode @add-qr-file="addQrFile" type="cns" :load-qr="true" />
        <p>{{ $t('new-inquiry.cns-upload.scan-text') }}</p>
        <LoadingDots class="cns-uploads__loading-dots" v-if="isLoading" />
        <label v-else class="cns-uploads__label" for="cns-upload">{{ $t('new-inquiry.cns-upload.upload-button') }}</label>
        <input id="cns-upload" type="file" class="cns-upload__input" @change="createFile" ref="cns-uploads-input">
      </div>
    </div>
    <Button @click="showUploadForm = true" v-if="!showUploadForm" class="mb-m">{{ $t('new-inquiry.cns-upload.add-button') }}</Button>
    <br>
    <Button @click="openModal('cns-uploads-modal')" lowercase inverse>
      {{ $t('new-inquiry.cns-upload.previous-button') }}
    </Button>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Modal from '@/02-molecules/Modal.vue'
import CnsUploadSelect from '@/01-atoms/Cns-upload-select.vue'
import CnsUpload from '@/02-molecules/Cns-upload.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import { getFileDownloads, postUploadsFile } from '@/api/uploads/files-api'
import QrCode from '@/02-molecules/Qr-code.vue'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import { DocumentFile } from '@/services/documents/DocumentService'
import eventHub from '@/event-hub'

export interface PreviousUploadTemplate {
  itemId: string;
  imageContent: string;
}

class Props {
  newCnsUploads?: Array<Upload>;
}

@Options({
  name: 'Cns-upload',
  components: {
    LoadingDots,
    QrCode,
    Button,
    Modal,
    CnsUploadSelect,
    CnsUpload
  },
  emits: ['add-new-cns-upload', 'remove-new-cns-upload', 'add-qr-file', 'add-previous-cns-uploads']
})
export default class CnsUploads extends Vue.with(Props) {
  isLoading = false

  previousCnsUploads = [] as Array<DocumentFile>
  previousCnsUploadsToAdd = [] as Array<PreviousUploadTemplate>

  beforeMount () {
    this.loadCnsDocuments()
  }

  addPreviousCnsUploads () {
    this.$emit('add-previous-cns-uploads', this.previousCnsUploadsToAdd)
    eventHub.$emit('close-modal')
  }

  updatePreviousCnsUploadSelect (itemId: string, imageContent: string, addItem: boolean) {
    if (addItem) {
      this.previousCnsUploadsToAdd.push({ itemId: itemId, imageContent: imageContent })
    } else {
      this.previousCnsUploadsToAdd = this.previousCnsUploadsToAdd.filter((upload) => { return upload.itemId !== itemId })
    }
  }

  loadCnsDocuments () {
    getFileDownloads('cns', 1, 6)
      .then((results) => {
        this.previousCnsUploads = results.data
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  newUpload = {
    title: '',
    file: '',
    id: '',
    fileType: 'cns'
  }

  createFile (ev: Event) {
    if (ev.target) {
      this.newUpload.title = ev.target['files'][0].name
      this.newUpload.file = URL.createObjectURL(ev.target['files'][0])

      const file = new FormData()
      file.append('title', ev.target['files'][0].name)
      file.append('file', ev.target['files'][0])
      file.append('fileType', 'cns')

      this.uploadFile(file)
    }
  }

  errorHandlerService = inject(ErrorHandlerKey)
  uploadFile (file: any) {
    this.isLoading = true
    postUploadsFile(file)
      .then((response) => {
        this.displayNewUpload(response.data.id)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  displayNewUpload (id: string) {
    this.newUpload['id'] = id
    this.$emit('add-new-cns-upload', this.newUpload)
    this.isLoading = false

    this.resetNewUploadForm()
  }

  showUploadForm = false
  resetNewUploadForm () {
    this.newUpload = {
      title: '',
      file: '',
      id: '',
      fileType: 'cns'
    }

    this.showUploadForm = false
  }

  addQrFile (file: any, type: 'default' | 'cns') {
    this.$emit('add-qr-file', file, type)
  }

  removeNewCnsUpload (id: string) {
    const uploadsInput: any = this.$refs['cns-uploads-input']
    if (uploadsInput) {
      uploadsInput.value = ''
    }
    this.$emit('remove-new-cns-upload', id)
  }
}
</script>
<style lang="scss" scoped>
.cns-uploads {
  border-radius: 10px;
  background-color: $blue-dark-10;
  padding: 33px 40px 33px;
  text-align: center;
}

.cns-uploads__grid {
  display: flex;
  flex-wrap: wrap;
}

.cns-upload {
  border: 4px dashed $blue-dark;
  border-radius: 10px;
  height: 360px;
  margin-right: -30px;
  margin-left: -30px;

  @include breakpoint(large down) {
    height: auto;
    max-height: 360px;
  }
}

.cns-upload__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 28px;
}

.cns-uploads__label {
  @include brandon-grotesque-bold;
  appearance: none;
  background: $blue-dark;
  border: none;
  border-radius: 30px;
  color: $white;
  display: inline-block;
  font-size: 14px;
  font-family: sans-serif;
  padding: 8px 23px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
}

.cns-upload__input {
  position: absolute;
  opacity: 0;
}

.cns-uploads__loading-dots {
  height: 32px;
}
</style>
