
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Modal-template',
  components: {

  }
})
export default class ModalTemplate extends Vue { }
