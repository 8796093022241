<template>
  <div class="page">
    <BackButton :to="{name: 'Dashboard'}" />
    <h1>Organisms</h1>
    <h2>General</h2>
    <section v-for="item in organismsList" v-bind:key="item">
      <h3>{{ item }}</h3>
      <component :is="item" />
      <div v-if="item === 'new-message-modal'">
        <Button @click="openModal('new-message')">Open New Message</Button>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BackButton from '@/02-molecules/Back-button.vue'
import Button from '@/01-atoms/Button.vue'
import Chat from '@/04-pages/06-Support/Support-inbox--chat.vue'
import ChatMessage from '@/02-molecules/Chat-message.vue'
import Confirmation from '@/03-organisms/Confirmation.vue'
import CnsUploads from '@/03-organisms/Cns-uploads.vue'
import ContentBox from '@/03-organisms/Content-box.vue'
import FamilyMember from '@/03-organisms/Family-member.vue'
import FamilyMemberCard from '@/03-organisms/Family-member-card.vue'
import HeaderSearch from '@/03-organisms/Header-search.vue'
import NewInquiryUploads from '@/03-organisms/New-inquiry-uploads.vue'
import NewMessageModal from '@/03-organisms/New-message-modal.vue'
import PackagePreview from '@/03-organisms/Package-preview.vue'
import QuickInquiry from '@/03-organisms/Quick-inquiry.vue'
import QuickUpload from '@/03-organisms/Quick-upload.vue'
import SettingCard from '@/03-organisms/Setting-card.vue'
import Slider from '@/03-organisms/Slider.vue'
import TripCancellationFormUploads from '@/03-organisms/Trip-cancellation-form-uploads.vue'

@Options({
  components: {
    BackButton,
    Button,
    Chat,
    ChatMessage,
    Confirmation,
    CnsUploads,
    ContentBox,
    FamilyMember,
    FamilyMemberCard,
    HeaderSearch,
    NewInquiryUploads,
    NewMessageModal,
    PackagePreview,
    QuickInquiry,
    QuickUpload,
    SettingCard,
    Slider,
    TripCancellationFormUploads
  }
})
export default class Organisms extends Vue {
  get organismsList () {
    const list: Array<string> = []
    require.context(
      '../03-organisms/',
      false,
      /.vue$/
    ).keys().forEach((fileName: string) => {
      const componentName = fileName
        .replace(/^\.\//, '')
        .replace(/\.\w+$/, '')
        .toLowerCase()
      if (componentName === 'navigation' || componentName === 'topbar' || componentName === 'modal-template') {
        // skip
      } else {
        list.push(componentName)
      }
    })
    return list
  }
}
</script>

<style lang="scss" scoped>
.code {
  background: aliceblue;
  padding: 15px;
  margin-top: 30px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  grid-gap: $medium;
  align-items: center;
  text-align: center;

  & > div {
    min-width: 300px;
  }
}

section {
  margin-top: 60px;
  padding: 0 30px;
}

h3 {
  border-bottom: 1px solid $blue-dark;
  margin-bottom: 30px;
}

.icon-swatch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height:100px;
  width:100px;

  svg {
    margin-bottom: 10px;
  }
}

.color-swatch {
  color: white;
  display: inline-block;
  height:100px;
  line-height: 100px;
  margin-bottom: 15px;
  text-align:center;
  width:100px;

  &--dark {
    color: $grey-dark;
  }
}
</style>
