
import { Options, Vue } from 'vue-class-component'
import Logo from '@/assets/logo-minimal.vue'
import IconButton from '@/01-atoms/Icon-button.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { format } from 'date-fns'

class Props {
  member!: object
}

@Options({
  name: 'Family-member-card',
  components: {
    Logo,
    IconButton
  }
})
export default class FamilyMemberCard extends Vue.with(Props) {
  cardIsZoomedIn = false

  zoomInCard () {
    this.cardIsZoomedIn = true
    disableBodyScroll(this.$refs['member-card'])
  }

  zoomOutCard () {
    this.cardIsZoomedIn = false
    enableBodyScroll(this.$refs['member-card'])
  }

  get formattedBirthDate () {
    const birthdate = this.member['birthDate']
    if (birthdate) {
      return format(new Date(this.member['birthDate']), 'dd-MM-yyyy')
    }
    return '/'
  }
}
